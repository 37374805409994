import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../img/logo-brif.png";
import { Layout, Menu, Breadcrumb, Icon } from "antd";
import { Dropdown } from "antd";
import { firebaseAuth, firebaseDatabase } from "../helpers/FirebaseHelper";
import { notification } from "antd";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;



const menu2 = (
  <Menu>
    <Menu.Item>
      <NavLink to="/dashboard/prices">Tarifa de precios</NavLink>
    </Menu.Item>
    <Menu.Item>
      <a
        onClick={() => {
          console.log("LOG OUT");
          firebaseAuth.signOut();
          window.location.reload();
        }}
      >
        Salir Sesion
      </a>
    </Menu.Item>
  </Menu>
);

class Navbar extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    firebaseDatabase.ref("security").on("value", (snapshot) => {
      snapshot.forEach((child) => {
        let notification = child.val();

        if (notification.hasSeen == false) {
          this.openNotification(
            "Un usuario esta en peligro",
            "Revise el panel de Emergencias",
            child.key,
            snapshot.key
          );
        }
      });
    });
  }

  openNotification(title, message, key, userId) {
    notification.open({
      message: title,
      description: message,
    });

    firebaseDatabase.ref("security").child(key).update({
      hasSeen: true,
    });
  }

  render() {
    return (
      <Layout>
        <Header className="header">
          <div className="logo-dvolada" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            style={{ paddingTop: "0.7em" }}
          >
            <Menu.Item key="5">
              <NavLink to="/dashboard">
                <Icon type="car" />
                <span>Mapa En Vivo</span>
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item key="1">
              <NavLink to="/dashboard/tiendas">
                <Icon type="shopping" />
                <span>Empresas</span>
              </NavLink>
            </Menu.Item> */}


            <Menu.Item key="5857">
              <NavLink to="/dashboard/cupons">
                <Icon type="percentage" />
                <span>Cupones</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="2">
              <NavLink to="/dashboard/t rips">
                <Icon type="dashboard" />
                <span>Viajes</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="26">
              <NavLink to="/dashboard/users/clients">
                <Icon type="car" />
                <span>Usuarios</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="20">
              <NavLink to="/dashboard/users/conductors">
                <Icon type="car" />
                <span>Conductores</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="27">
              <NavLink to="/dashboard/viajes-taximetro">
                <Icon type="car" />
                <span>Verificacion</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="25738">
              <NavLink to="/dashboard/verification">
                <Icon type="car" />
                <span>Viajes Taximetro</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="255738">
              <NavLink to="/dashboard/tiendas">
                <Icon type="car" />
                <span>Comercios Afiliados</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="28">
              <NavLink to="/dashboard/autos">
                <Icon type="car" />
                <span>Vehiculos</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="3">
              <NavLink to="/dashboard/alertas">
                <Icon type="shopping-cart" />
                <span>Notificaciones</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="8">
              <NavLink to="/dashboard/logs">
                <Icon type="book" />
                <span>Bitacora de viajes</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="5">
              <NavLink to="/dashboard/prices">
                <Icon type="shopping-cart" />
                <span>Ajustes</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="75">
              <a
                onClick={() => {
                  console.log(`LOG OUT`);
                  firebaseAuth.signOut();
                }}
              >
                <Icon type="logout" />
                <span>Salir Sesion</span>
              </a>
            </Menu.Item>
          </Menu>
        </Header>
      </Layout>
    );
    // <nav className="navbar">
    //   <div className="row p-1">
    //     <div className="col-sm-1">
    //       <div className="row">
    //         <div className="col" style={{ margin: '0 0 1em 2em' }}>
    //           <NavLink to="/dashboard/">
    //             <img src={Logo} width="65" style={{ cursor: 'pointer' }} />
    //           </NavLink>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-sm-10" style={{ marginTop: '-10px', marginLeft: "0em" }}>
    //       <div className="row align-center h-100" style={{}}>
    //         <ul className="nav-list">
    //           <li>
    //             <NavLink to="/dashboard/">Mapa En Vivo</NavLink>
    //           </li>
    //           <li>
    //           <li>
    //             <NavLink to="/dashboard/trips">Viajes</NavLink>
    //           </li>
    //           <li>
    //             <NavLink to="/dashboard/autos">Vehiculos</NavLink>
    //           </li>
    //           <li>
    //             <NavLink to="/dashboard/emergencias">Emergencias</NavLink>
    //           </li>
    //           <li>
    //             <NavLink to="/dashboard/logs">Logs</NavLink>
    //           </li>
    //           <li>
    //             <Dropdown overlay={menu2}>
    //               <a>Ajustes</a>
    //             </Dropdown>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </nav>
  }
}

export default Navbar;
