import { Grid, Row, Col } from "react-flexbox-grid";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import React, { Component } from "react";
import Dashboard from "./pages/private/Dashboard";
import { firebaseAuth } from "./helpers/FirebaseHelper";
import Login from "./pages/public/Login";
import "./App.css";
import { firebaseDatabase } from "./helpers/FirebaseHelper";
import { Modal } from "antd";
import { API_GOOGLE_KEY, BASE_URL } from "./data/constants";
import Loading from "./components/Loading";
import ShareCompany from "./pages/private/ShareCompany";
import ResetPassword from "./pages/private/ResetPassword";

import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Input,
} from "react-google-maps";

import RedMarker from "./img/markers/redMarker.png";

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

const Map = withScriptjs(
  withGoogleMap((props) => {
    let markerOffline = new window.google.maps.MarkerImage(
      RedMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    return (
      <GoogleMap
        defaultZoom={12}
        zoom={props.zoom}
        ref={(map) => {
          if (map && props.isFirstLoad) {
            props.doneShowingMap();
          }

          props.onMapReady(map);
        }}
        center={props.center}
      >
        <Marker
          position={props.center}
          icon={markerOffline}
          defaultAnimation={window.google.maps.Animation.DROP}
          // onClick={() => {
          //   props.onMapClick(conductor);
          // }}
        />
      </GoogleMap>
    );
  })
);

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: true,
      loading: true,
      visibleVerification: false,
      usersPending: [],
      logs: [],
      zoom: 14,
      currentUser: {},
      currentRide: {
        client: {},
      },
      visibleLostTrip: false,
      map: null,
      isForRender: false,
      isFirstLoad: true,
      center: { lat: 19.487487, lng: -99.130259 },
    };
  }

  componentDidMount() {
    this.removeListener = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        if (user.uid) {
          this.setState({
            loading: false,
            authed: true,
          });
        }
      } else {
        this.setState({
          loading: false,
          authed: false,
        });
      }
    });

  
    firebaseDatabase.ref("verification").on("child_added", (snapshot) => {
      const { usersPending } = this.state;
      usersPending.push({ ...snapshot.val() });
      this.checkVerification(usersPending);
      firebaseDatabase.ref("verification").child(snapshot.key).remove();
    });

    firebaseDatabase.ref("logs").on("child_added", (snapshot) => {
      const { logs } = this.state;
      logs.push({ ...snapshot.val(), key: snapshot.key });
      this.checkLogs(logs);
    });
  }

  checkLogs = (array) => {
    if (array.length > 0) {
      let ride = array[0];

      const { visibleLostTrip } = this.state;

      if (!visibleLostTrip) {
        let audio = new Audio(
          "https://firebasestorage.googleapis.com/v0/b/alize-taxi-b96bf.appspot.com/o/short_notification.mp3?alt=media&token=2a9a501f-7a74-48ac-a7c0-d5289e601db0"
        );
        audio.play();

        this.setState({
          currentRide: {
            ...ride,
          },
          center: {
            lat: ride.origin_location.lat,
            lng: ride.origin_location.lon,
          },
          visibleLostTrip: true,
        });
      }
    }
  };

  checkVerification = (array) => {
    if (array.length > 0) {
      let user = array[0];

      const { visibleVerification } = this.state;

      if (!visibleVerification) {
        let audio = new Audio(
          "https://firebasestorage.googleapis.com/v0/b/alize-taxi-b96bf.appspot.com/o/short_notification.mp3?alt=media&token=2a9a501f-7a74-48ac-a7c0-d5289e601db0"
        );
        audio.play();

        this.setState({
          currentUser: user,
          visibleVerification: true,
        });

        console.log(this.state);
      }
    }
  };

  render() {
    return this.state.loading === true ? (
      <Loading />
    ) : (
      <div className="h-100">
        <Router>
          <Modal
            title="Verificacion de pago en effectivo"
            visible={this.state.visibleVerification}
            onOk={() => {
              const { currentUser } = this.state;

              let ref = firebaseDatabase
                .ref("clients")
                .child(currentUser.client._id)
                .child("verification");

              ref
                .update({
                  status: "success",
                })
                .then(() => {
                  const { usersPending } = this.state;

                  let array = usersPending.filter((item) => {
                    return item.client._id != currentUser.client._id;
                  });

                  this.setState({
                    visibleVerification: false,
                    usersPending: array,
                  });

                  this.checkVerification(array);

                  ref.remove();
                });
            }}
            width={700}
            cancelText="Rechazar usuario"
            okText="Aceptar Usuario"
            onCancel={() => {
              const { currentUser } = this.state;

              let ref = firebaseDatabase
                .ref("clients")
                .child(currentUser.client._id)
                .child("verification");

              ref
                .update({
                  status: "rejected",
                })
                .then(() => {
                  const { usersPending } = this.state;

                  let array = usersPending.filter((item) => {
                    return item.client._id != currentUser.client._id;
                  });

                  this.setState({
                    visibleVerification: false,
                    usersPending: array,
                  });

                  this.checkVerification(array);

                  ref.remove();
                });
            }}
          >
            <div>
              <h1>
                ¡{" "}
                {this.state.currentUser.client
                  ? this.state.currentUser.client.first_name
                  : "Usuario"}{" "}
                se quiere validar!
              </h1>

              <Row>
                <Col md={6}>
                  <img
                    src={
                      this.state.currentUser ? this.state.currentUser.ine : ""
                    }
                    width={"100%"}
                    // height={700}
                    alt=""
                  />
                </Col>
                <Col md={6}>
                  <img
                    src={
                      this.state.currentUser ? this.state.currentUser.selfie : ""
                    }
                    width={"100%"}
                    // height={700}
                    alt=""
                  />
                </Col>
              </Row>
            </div>
          </Modal>

          <Modal
            title="Viaje no encontrado por cliente"
            visible={this.state.visibleLostTrip}
            onOk={() => {
              this.setState({
                visibleLostTrip: false,
              });

              const { logs } = this.state;

              let array = logs.filter((item) => {
                return this.state.currentRide.key != item.key;
              });

              this.setState({
                visibleLostTrip: false,
                logs: array,
              });

              window.location.href = `http://admin.aliz.com.mx/dashboard/create-trip/${this.state.currentRide.key}`;
            }}
            width={700}
            okText="Asignar conductor"
            cancelText="Ignorar"
            onCancel={() => {
              console.log(this.state.currentRide.key);

              firebaseDatabase
                .ref("logs")
                .child(this.state.currentRide.key)
                .remove();

              this.setState({
                visibleLostTrip: false,
              });

              const { logs } = this.state;

              let array = logs.filter((item) => {
                return this.state.currentRide.key != item.key;
              });

              this.setState({
                visibleLostTrip: false,
                logs: array,
              });

              setTimeout(() => {
                this.checkLogs(array);
              }, 500);
            }}
          >
            <div style={{ height: "100%" }}>
              <h1>
                {this.state.currentRide.client.first_name} quiere solicitar
                viaje, pero no encuentra conductor
              </h1>
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places`}
                loadingElement={
                  <div style={{ height: `600px`, width: "100%" }} />
                }
                containerElement={
                  <div style={{ height: `600px`, width: "100%" }} />
                }
                zoom={this.state.zoom}
                mapElement={<div style={{ height: `600px`, width: "100%" }} />}
                doneShowingMap={() => {
                  this.setState({
                    isFirstLoad: false,
                  });
                }}
                isForRender={this.state.isForRender}
                center={this.state.center}
                isFirstLoad={this.state.isFirstLoad}
                onMapReady={(ref) => {
                  if (ref && this.state.map == null) {
                    this.setState({
                      map: ref,
                    });
                  }
                }}
              />
            </div>
          </Modal>
          <Switch>
            <PrivateRoute
              authed={this.state.authed}
              path="/dashboard"
              component={Dashboard}
            />
            <PublicRoute
              authed={this.state.authed}
              path="/login"
              component={Login}
            />
            <Route path={`/password/:id/:type`} component={ResetPassword} />
            <Route path={"/company/:id"} component={ShareCompany} />
            <Route path={"/"} render={() => <Redirect to={"/dashboard"} />} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
