import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";

import {
  Tabs,
  Typography,
  Table,
  Rate,
  DatePicker,
  Select,
  Avatar,
  Card,
  Icon,
  List,
  Tag,
  Popconfirm,
  Input,
  Radio,
  message,
  Skeleton,
  Modal,
} from "antd";


const { Title, Text } = Typography;

const formatter = new Intl.NumberFormat("en-MX", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const CardResumeData = (props) => {
  const style = {
    colorGray: { color: "#7a7a7a" },
    paddingAround: { padding: "1em" },
  };

  return (
    <Col md={4} style={style.paddingAround}>
      <Row className="card-resume--user">
        <Col xs={12}>
          <Title level={4} style={style.colorGray}>
            {props.title}
          </Title>
        </Col>
        <Col xs={12}>
          <Title>
            {props.type === "quantity"
              ? props.number
              : formatter.format(props.number)}
          </Title>
        </Col>
      </Row>
    </Col>
  );
};

export default CardResumeData;
