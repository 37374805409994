import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Logo from '../../img/logo-brif.png'
import { Button, Input } from 'antd'
import { login } from '../../helpers/FirebaseHelper'
import { Alert } from 'antd';




export class Login extends Component {


    constructor() {
        super()

        this.state = {
            email: '',
            password: '',
            messageError: '',
            open: false,
            loading: false
        }

        this.handleLogin = this.handleLogin.bind(this)
        this.showErrorMessage = this.showErrorMessage.bind(this)

    }


    handleLogin() {

        const { email, password } = this.state


        if (email && password) {
            this.setState({
                loading: true,
            })

            login(email, password).then(result => {
                console.log(result)
            }).catch(error => {
                console.log("ERROR")
                console.log(error)
                switch (error.code) {
                    case "auth/user-not-found": {
                        this.showErrorMessage(`Este usuario no existe`)
                        break
                    }
                    case "auth/user-not-admin": {
                        this.showErrorMessage(`Este usuario no es administrador`)
                        break
                    }

                    case "auth/wrong-password": {
                        this.showErrorMessage(`Contraseña incorrecta`)
                        break
                    }

                    default: {
                        this.showErrorMessage(`Error, intente con otros datos`)
                        break
                    }
                }
            })

        } else {
            this.setState({
                messageError: 'Inserte correctamente todo los datos'
            })
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }


    showErrorMessage(message) {
        this.setState({
            loading: false,
            messageError: message
        })
    }



    render() {
        return (
            <Grid fluid className="n-margin">
                <Row style={{ height: '100%' }}>
                    <Col md={3} style={{ padding: '3.5em', position: 'relative' }}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '20px' }}>
                                <Row>
                                    <Col md={12}>
                                        <div class="loginInputArea">
                                            <div style={{ width: "100%" }}>
                                                <img src={Logo} alt="" width={130} />
                                                {
                                                    this.state.messageError ? <Alert
                                                        message={this.state.messageError}
                                                        type="error"
                                                        style={{ marginTop: "3em" }}
                                                        closable
                                                        onClose={() => {
                                                            this.setState({
                                                                messageError: ''
                                                            })
                                                        }}
                                                    /> : ''
                                                }


                                                <Input
                                                    autofocus
                                                    type="text" class="loginInput username empty" placeholder="Username" style={{ marginBottom: "1em", marginTop: "1.5em" }}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            email: e.target.value
                                                        })
                                                    }}

                                                    // onKeyPress={() => {
                                                    //     this.handleLogin()
                                                    // }}
                                                />
                                                <Input type="password" class="loginInput password empty" placeholder="Password"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            password: e.target.value
                                                        })
                                                    }}
                                                    // onKeyPress={() => {
                                                    //     this.handleLogin()
                                                    // }}
                                                />
                                                {/* <div style={{ marginLeft: "0.5em", textAlign: "left"  , marginTop : "1.5em"}}>
                                                    <a className="mt-1" href="/password">¿Olvidaste tu contraseña?</a>
                                                </div> */}
                                                <div class="btnLogin-wrapper">
                                                    <Button className="login-button" onClick={this.handleLogin} loading={this.state.loading}>Iniciar Sesion</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>



                        <div style={{ position: 'absolute', bottom: 0, marginBottom: "1em" }}>
                            <p>Politicas de privacidad | Terminos y condiciones</p>
                            <p>© Geocab Systems. Todo los derechos reservados</p>
                        </div>
                    </Col>
                    <Col md className="n-margin">
                        <div className="login-right-side" />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default Login
