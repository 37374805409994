import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Axios from "axios";
import { Avatar, Card, Icon, List, Tag } from "antd";
import { BASE_URL } from "../../data/constants";
import Tripitem from "../../components/TripItem";
import { Input, Button } from "antd";
import { AST_PrefixedTemplateString } from "terser";
import { Table, Badge, Menu, Dropdown, Collapse } from "antd";
import moment from "moment";
import "moment/locale/es";
import { firebaseDatabase } from "../../helpers/FirebaseHelper";

const { Panel } = Collapse;

const columns2 = [
  {
    title: "Accion",
    dataIndex: "track",
    key: "track",
  },
];

export class Trips extends Component {
  constructor() {
    super();
    this.state = {
      rides: [],
      ridesToShow: [],
      name_client: "",
      name_conductor: "",
      isReset: false,
    };
    this.NestedTable = this.NestedTable.bind(this);
  }

  componentDidMount() {
    firebaseDatabase
      .ref("logs_admin")
      .once("value")
      .then((snapshot) => {
        let data = [];
        snapshot.forEach((child) => {
          let ride = child.val();

          let track = [];

          for (let key in ride.status) {
            track.push({ track: ride.status[key] });
          }

          data.push({
            ...ride,
            status: track,
          });
        });

        this.setState({
          ridesToShow: data.reverse(),
        });
      });
  }

  NestedTable() {
    const expandedRowRender = () => {
      const columns = [
        { title: "Conductor", dataIndex: "conductor", key: "conductor" },
        {
          title: "Fecha de envio de solicitud",
          dataIndex: "date",
          key: "date",
        },
        { title: "Fecha de envio de recibido", dataIndex: "date", key: "date" },
        {
          title: "Status de recibido",
          key: "state",
          render: () => (
            <span>
              <Badge status="success" />
              Si le llego la alerta
            </span>
          ),
        },
        {
          title: "Status de aceptación",
          key: "state",
          render: () => (
            <span>
              <Badge status="success" />
              Si acepto el viaje
            </span>
          ),
        },
      ];

      const data = [];
      for (let i = 0; i < 1; ++i) {
        data.push({
          key: i,
          conductor: "Luis Santiago",
          date: "2014-12-24 23:12:00",
          name: "This is production name",
          upgradeNum: "Upgraded: 56",
        });
      }
      return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns = [
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        render: (client) => <p>{client.first_name}</p>,
      },
      { title: "Plataforma", dataIndex: "platform", key: "platform" },
      { title: "Version", dataIndex: "version", key: "version" },
      { title: "Fecha", dataIndex: "date", key: "date" },
      {
        title: "Dirreción origen",
        dataIndex: "direction_destination",
        key: "direction_destination",
      },
      {
        title: "Dirección destino",
        dataIndex: "direction_destination",
        key: "direction_destination",
      },
      {
        title: "Logs",
        dataIndex: "status",
        key: "status",
        render: (status, object, position) => {
          return (
            <Collapse defaultActiveKey={["1"]}>
              <Table
                pagination={false}
                columns={columns2}
                dataSource={object.status}
              />
            </Collapse>
          );
        },
      },
    ];

    const data = [];

    return (
      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandedRowRender={expandedRowRender}
        dataSource={this.state.ridesToShow}
      />
    );
  }

  render() {
    let NestedTable = this.NestedTable;
    return (
      <Grid fluid style={{ overflowY: "auto" }}>
        <Row style={{ margin: "2em" }}>
          <Col lg={12}>
            <Row>
              <Col md={5}>
                <h1>Bitcora de Viajes</h1>
              </Col>
            </Row>
            <NestedTable />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Trips;
