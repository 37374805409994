import React, { Component } from "react";
import { ReactDOM } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import {
  Alert,
  Upload,
  Input,
  Icon,
  Rate,
  Switch,
  Select,
  Avatar,
  Tabs,
  Progress,
  message,
  Typography,
  Button,
  Modal,
  Collapse,
  Table,
  Divider,
  Radio,
  Checkbox,
  Tag,
  DatePicker,
} from "antd";
import { BASE_URL, API_GOOGLE_KEY } from "../../data/constants";
import { firebaseStorage } from "../../helpers/FirebaseHelper";
import Autocomplete from "react-google-autocomplete";

import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

import TemplateLogo from "../../img/logo-brif.png";
import Axios from "axios";
import axios from "axios";
import moment from "moment";
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const { MonthPicker } = DatePicker;

const Map = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 19.382596, lng: -99.153247 }}
    center={props.markerPosition}
    onClick={props.onMapClick}
  >
    {props.isMarkerShown && <Marker position={props.markerPosition} />}
  </GoogleMap>
));

const columns = [
  {
    title: "Lapso",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <a>Editar</a>
        <Divider type="vertical" />
        <a>Eliminar</a>
      </span>
    ),
  },
];

const dataForTable = [
  {
    key: "1",
    name: "00:00 - 12:59",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
];

const days = [
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
  "Domingo",
];

const HourComponent = () => {
  return (
    <Collapse>
      {days.map((item, y) => {
        return (
          <Panel header={item} key={y}>
            <div className="bg-white-colapse">
              <Table columns={columns} dataSource={dataForTable} />
            </div>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default class StoreProfile extends Component {
  state = {
    storeInfo: { type: "food", isSearchMarker: true, sub_categories: [] },
    isUploadingLogo: false,
    isUploadingBanner: false,
    fileListLogo: [],
    percentage: 0,
    id: "",
    subscriptions: [],
    sub_categories: [],
    cupons: [],
    promotions: [],
    promotionToCreate: {},
    currentOrder: {},
    date_limit: "",
    subscription: "",
    date_subscription: "",
    visibleModalSubscription: false,
    result: [],
    visible: false,
    loadingSubscription: false,
    visiblePromotionModal: false,
    visibleCuponModal: false,
    loadingUpdate: false,
    isAdmin: false,
    visibleModalInform: false,
    visibleInfo: false,
    monthSelected: null,
    hasAlreadyPickedLocation: false,
    markerPosition: { lat: 19.382596, lng: -99.153247 },
    changeTable: false,
    showDocument: false,
    total_payment: 0,
    yearSelected: 2021,
    iva: 0,
    states: [],
    payment_subtotal: 0,
    loading: true,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalInfo = () => {
    this.setState({
      visibleInfo: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visibleInfo: false,
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      this.setState({ id });
      this.findStoreById(id);
    }

    this.setState({
      loading: true,
    });

    let momentNow = moment();

    this.setState({
      month: momentNow,
    });
  }

  updateDirections = (latitude, longitud) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitud}&key=${API_GOOGLE_KEY}`
      )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          let results = data.results;

          if (results.length > 0) {
            let address_components = results[0];
            let direction = address_components.formatted_address;
            console.log(direction);
            this.setState({
              storeInfo: {
                ...this.state.storeInfo,
                direction,
                location: {
                  lat: latitude,
                  lon: longitud,
                },
              },
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
        });
      });
  };

  findStoreById = (id) => {
    this.setState({
      loading: true,
    });
    if (id) {
      axios
        .get(`${BASE_URL}/super_user/store/${id}`)
        .then((response) => {
          console.log(response.status);
          if (response.status == 200) {
            this.setState({
              storeInfo: {
                ...response.data,
                isSearchMarker: true,
              },
            });

            let location = response.data.location;
            if (location.lat) {
              this.setState({
                hasAlreadyPickedLocation: true,
                markerPosition: { lat: location.lat, lng: location.lon },
              });
            }

            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            loading: false,
          });
        });
    }
  };

  updateData = (isNotified) => {
    this.setState({
      loading: true,
    });
    let data = { ...this.state.storeInfo };
    data["id"] = this.state.storeInfo._id;

    delete data._id;

    axios
      .put(`${BASE_URL}/super_user/store/${this.state.id}`, {
        ...data,
      })
      .then((response) => {
        this.setState({
          visibleInfo: false,
          loadingUpdate: false,
          loading: false,
        });
        if (isNotified) {
          message.success("Datos actualizados");
        }
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
        });
      });

    axios
      .put(`${BASE_URL}/super_user/update_location/${this.state.id}`, {
        lat: this.state.storeInfo.location.lat,
        lon: this.state.storeInfo.location.lon,
        id: this.state.id,
      })
      .then((response) => {
        if (isNotified) {
          message.success("Direccion Actualizado");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
        });
      });
  };

  uploadFile = (id, file, onImageReady) => {
    let imagesFilesRef = firebaseStorage.child(`logo/${this.state.id}/${id}`);
    var task = imagesFilesRef.put(file.originFileObj);

    task.on("state_changed", (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.showProgress(percentage);
    });

    task.then((child) => {
      imagesFilesRef.getDownloadURL().then((urlSnap) => {
        onImageReady(urlSnap);
        this.setState({
          percentage: 0,
        });
      });
    });
  };

  showProgress = (percentage) => {
    this.setState({ percentage });
  };

  handleImageInput = (type, file) => {
    let files = [file.file];

    if (FileReader && files && files.length) {
      var fr = new FileReader();

      fr.onload = () => {
        var img = new Image();
        img.src = fr.result;

        img.onload = () => {
          let width = img.width;
          let height = img.height;

          var isAbleToUpload = false;
          var defaultSize = "";

          if (type == "logo") {
            if (width == 500 && height == 500) {
              isAbleToUpload = true;
            }

            defaultSize = "500x500";
          } else {
            console.log(`${width} ${height} ${height >= 700 && height <= 720}`);

            if ((width) => 1920 && height >= 700 && height <= 720) {
              isAbleToUpload = true;
            }

            defaultSize = "1920x700";
          }

          if (isAbleToUpload) {
            if (type == "logo") {
              this.uploadFile("logo", file, (url) => {
                this.setState({
                  storeInfo: {
                    ...this.state.storeInfo,
                    url_logo: url,
                    percentage: 0,
                    isUploadingLogo: false,
                  },
                });

                this.updateData(true);
              });
            } else {
              this.setState({
                isUploadingBanner: true,
              });

              this.uploadFile("banner", file, (url) => {
                this.setState({
                  storeInfo: {
                    ...this.state.storeInfo,
                    banner: url,
                    percentage: 0,
                  },
                  isUploadingBanner: false,
                });

                this.updateData(true);
              });
            }
          } else {
            message.error(
              "El tamaño de la imagen es incorrecto, se necesita " + defaultSize
            );
          }
        };
      };

      fr.readAsDataURL(file.originFileObj);
    }
  };

  showModalStore() {
    let type = "Restaurante";
    let sub_category = "";

    if (this.state.storeInfo) {
      if (this.state.storeInfo.sub_category) {
        sub_category = this.state.storeInfo.sub_category.name;
      }

      if (this.state.storeInfo.type) {
        switch (this.state.storeInfo.type) {
          case "food": {
            type = "Restaurante";
            break;
          }

          case "store": {
            type = "Tienda";

            break;
          }
        }
      }
    }

    return (
      <Modal
        title="Información de Tienda"
        visible={this.state.visibleInfo}
        confirmLoading={this.state.loadingUpdate}
        onOk={() => {
          this.setState({
            loadingUpdate: true,
          });

          this.updateData(true);
        }}
        width={900}
        onCancel={this.handleCancel}
      >
        <Row style={{ justifyContent: "left" }}>
          <Col md={12}>
            <div>
              <h1 style={{ marginBottom: 0, marginTop: 0 }}>
                Llene correctamente los datos
              </h1>
            </div>

            {this.state.errorMessage ? (
              <Alert
                description={this.state.errorMessage}
                type="error"
                closable
              />
            ) : (
              ""
            )}
          </Col>

          <Col md={12} style={{ marginTop: "1em" }}>
            <Input
              placeholder="Nombre del negocio"
              value={this.state.storeInfo.name}
              onChange={(e) => {
                this.setState({
                  storeInfo: {
                    ...this.state.storeInfo,
                    name: e.target.value,
                  },
                });
              }}
            />
          </Col>

          <Col md={12} style={{ marginTop: "1em" }}>
            <Input
              placeholder="Email"
              value={this.state.storeInfo.email}
              onChange={(e) => {
                this.setState({
                  storeInfo: {
                    ...this.state.storeInfo,
                    email: e.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col md={12} style={{ marginTop: "1em" }}>
            <Input
              placeholder="Pagina web"
              value={this.state.storeInfo.website}
              onChange={(e) => {
                this.setState({
                  storeInfo: {
                    ...this.state.storeInfo,
                    website: e.target.value,
                  },
                });
              }}
            />
          </Col>
          {this.state.storeInfo.isSearchMarker ? null : (
            <Col md={12} style={{ marginTop: "1em" }}>
              <Autocomplete
                style={{ width: "100%" }}
                className="ant-input"
                placeholder="Busca tu dirección"
                ref={(direction) => (this.direction = direction)}
                onPlaceSelected={(place) => {
                  console.log(place);

                  this.setState({
                    markerPosition: place.geometry.location,
                    hasAlreadyPickedLocation: true,
                  });

                  this.setState({
                    storeInfo: {
                      ...this.state.storeInfo,
                      direction: place.formatted_address,
                      location: {
                        lat: place.geometry.location.lat(),
                        lon: place.geometry.location.lng(),
                      },
                    },
                  });
                }}
                types={["address"]}
                componentRestrictions={{ country: "mx" }}
              />
            </Col>
          )}

          <Col md={12} style={{ marginTop: "1em" }}>
            <div className="d-flex">
              <p style={{ marginBottom: "0", marginTop: "1px" }}>
                Direccion por Marker
              </p>
              <Switch
                style={{ marginLeft: "1em" }}
                defaultChecked
                onChange={(value) => {
                  let store = this.state.storeInfo;
                  store.isSearchMarker = value;
                  this.setState({ store });
                  console.log(value);
                }}
              />
            </div>

            <p style={{ marginTop: "1em" }}>
              {this.state.storeInfo ? this.state.storeInfo.direction : ""}
            </p>
          </Col>

          <Col md={12} style={{ marginTop: "1em" }}>
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}`}
              loadingElement={<div style={{ height: `300px` }} />}
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `300px` }} />}
              markerPosition={this.state.markerPosition}
              onMapClick={(e) => {
                this.setState({
                  markerPosition: e.latLng,
                  hasAlreadyPickedLocation: true,
                  center: e.latLng,
                });
                this.updateDirections(e.latLng.lat(), e.latLng.lng());
              }}
              isMarkerShown={true}
            />
          </Col>
        </Row>
      </Modal>
    );
  }

  render() {
    if (this.state.loading === true) {
      return <div>Cargando...</div>;
    }
    let url = TemplateLogo;
    let banner = "";

    if (this.state.storeInfo.banner) {
      banner = this.state.storeInfo.banner;
    }

    if (this.state.storeInfo.url_logo) {
      url = this.state.storeInfo.url_logo;
    }

    return (
      <Grid fluid>
        {this.showModalStore()}
        <div
          ref={(ref) => (this._div = ref)}
          id="WhateverIdYouWantToScrollTo"
        />
        <Row className="p-2--profile">
          <DetailsInformationUser
            state={this.state}
            banner={banner}
            url={url}
            storeInfo={this.state.storeInfo}
            onImage={this.handleImageInput}
            onDate={this.handleChangeOnDate}
            onSelect={this.handleChangeSelect}
            onShowModalInfo={this.showModalInfo}
            history={this.props.history}
          />
        </Row>
      </Grid>
    );
  }
}

const DetailsInformationUser = (props) => {
  //rgba(255, 143, 0, 1)
  const style = {
    paddingAround: { padding: "0 1em" },
    colorWhite: { color: "#000" },
    bgContainer: { background: "white" },
    wrapperProfile: { padding: "0px", background: "white" },
    wrapperScore: { background: "white", padding: "1em 0" },
    paddingTitleTimeActive: { padding: "8em 4em 0px" },
    paddingTimeActive: { padding: "1em 0 5em" },
    datePicker: { padding: "1em" },
    paddingTopSelect: { padding: "6em 0 0 " },
  };

  
  return (
    <Col md={12} style={style.bgContainer}>
      <Row middle="xs" center="md" start="lg" style={style.wrapperProfile}>
        <Col xs={12} style={{ padding: "0px" }}>
          <div
            className="banner-store-main"
            style={{
              backgroundImage: `url(${props.banner})`,
              position: "relative",
              height: "220px",
            }}
          >
            <Upload
              multiple={false}
              accept="image/*"
              customRequest={() => {}}
              onChange={(file) => props.onImage("logo", file.file)}
            >
              <div className="thumbnail logo-inner">
                <div className="logo">
                  {/* <img src={url_thumnail} alt="" /> */}
                  <img src={props.url} id="image_logo" alt="" />
                </div>
              </div>
            </Upload>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Upload
                multiple={false}
                accept="image/*"
                customRequest={() => {}}
                onChange={(file) => props.onImage("banner", file.file)}
              >
                <button
                  type="dashed"
                  className="button-change-banner gradient"
                  style={{ width: "200px" }}
                >
                  Cambiar Portada
                </button>
              </Upload>
            </div>
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2 style={{ fontSize: "3em", marginTop: "1em" }}>
              {props.storeInfo.name}
            </h2>
            <Button onClick={props.onShowModalInfo}>
              Editar Informacion
            </Button>
          </div>
        </Col>
      </Row>
    </Col>
  );
};
