import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCugXG6JSVfBFhnCXECm7hIqhaV0jwSC5M",
  authDomain: "geocab-bc6ce.firebaseapp.com",
  databaseURL: "https://geocab-bc6ce.firebaseio.com",
  projectId: "geocab-bc6ce",
  storageBucket: "geocab-bc6ce.appspot.com",
  messagingSenderId: "949795217687",
  appId: "1:949795217687:web:bc973786168a36e0511752",
  measurementId: "G-64HVB80L7D",
};

firebase.initializeApp(config);
firebase.analytics();

export const getUid = () => firebaseAuth.currentUser;

export const firebaseAuth = firebase.auth();

export const firebaseDatabase = firebase.database();
export const firebaseStorage = firebase.storage().ref();

export const login = (email, password) =>
  firebaseAuth.signInWithEmailAndPassword(email, password);
