import React, { Component } from 'react'
import Logo from '../img/logo-brif.png'
import { thisExpression } from '@babel/types'

export class Loading extends Component {

    render() {
        return (
            <div className={'h-100 w-100'}>
                <div className="text-center h-100 align-center-vertical" style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ textAlign: "center", alignSelf: "center" }}>
                        <img src={Logo} alt="" width="300" />
                        <h1>Cargando ...</h1>
                    </div>
                </div>
            </div>
        )
    }
}

export default Loading
