import React, { Component } from "react";
import {
  Tabs,
  Radio,
  Card,
  Icon,
  Modal,
  Button,
  Input,
  Alert,
  message,
  DatePicker,
  InputNumber,
  Tag,
  Table,
} from "antd";
import { Badge, Menu, Dropdown } from "antd";

import { NavLink } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../data/constants";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import CarTemplate from "../../img/car-template.png";

const { confirm } = Modal;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

export class Cupon extends Component {
  constructor() {
    super();
    this.state = {
      cupons: [],
      visible: false,
      loading: false,
      cuponToCreate: {},
    };
  }

  componentDidMount() {
    axios.get(`${BASE_URL}/super_user/cupon_discount`).then((response) => {
      this.setState({
        cupons: response.data.result,
      });
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    // let expandedRowRender = () => {
    //   const columns = [
    //     { title: "Date", dataIndex: "date", key: "date" },
    //     { title: "Name", dataIndex: "name", key: "name" },
    //     {
    //       title: "Status",
    //       key: "state",
    //       render: () => (
    //         <span>
    //           <Badge status="success" />
    //           Finished
    //         </span>
    //       ),
    //     },
    //     { title: "Upgrade Status", dataIndex: "upgradeNum", key: "upgradeNum" },
    //     {
    //       title: "Action",
    //       dataIndex: "operation",
    //       key: "operation",
    //       render: () => (
    //         <div>
    //           <a>Pause</a>
    //           <a>Stop</a>
    //         </div>
    //       ),
    //     },
    //   ];

    //   const data = [];
    //   for (let i = 0; i < 3; ++i) {
    //     data.push({
    //       key: i,
    //       date: "2014-12-24 23:12:00",
    //       name: "This is production name",
    //       upgradeNum: "Upgraded: 56",
    //     });
    //   }
    //   return <Table columns={columns} dataSource={data} pagination={false} />;
    // };

    return (
      <div
        style={{
          width: "100%",
          margin: "2em",
          overflowY: "auto",
          height: "90%",
        }}
        className="title"
      >
        <div>
          <Modal
            title="Crear un Cupon"
            visible={this.state.visible}
            confirmLoading={this.state.loading}
            onOk={() => {
              this.setState({
                loading: true,
              });

              axios
                .post(
                  `${BASE_URL}/super_user/cupon_discount`,
                  this.state.cuponToCreate
                )
                .then((response) => {
                  let cupon = response.data.result;
                  const { cupons } = this.state;
                  cupons.push(cupon);
                  this.setState({ cupons });

                  this.setState({
                    cuponToCreate: {},
                    visible: false,
                    loading: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    loading: false,
                  });
                });
            }}
            onCancel={this.handleCancel}
          >
            <Row style={{ padding: "0.5em" }}>
              <div className="container-input">
                <h1>Nombre del cupon</h1>
                <Input
                  placeholder="Ingrese el nombre"
                  value={this.state.cuponToCreate.code}
                  onChange={(e) => {
                    this.setState({
                      cuponToCreate: {
                        ...this.state.cuponToCreate,
                        code: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  style={{ marginTop: "1em" }}
                  value={this.state.cuponToCreate.limit}
                  inputMode="numeric"
                  placeholder="Limite de uso global"
                  style={{ width: "100%", marginTop: "1em" }}
                  //min={1}
                  onChange={(e) => {
                    console.log(!isNaN(e));
                    if (!isNaN(e.target.value)) {
                      this.setState({
                        cuponToCreate: {
                          ...this.state.cuponToCreate,
                          limit: e.target.value,
                        },
                      });
                    }
                  }}
                />

                <Input
                  style={{ marginTop: "1em" }}
                  value={this.state.cuponToCreate.limit_use_user}
                  inputMode="numeric"
                  placeholder="Veces en que se puede usar un cupon"
                  style={{ width: "100%", marginTop: "1em" }}
                  min={1}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      this.setState({
                        cuponToCreate: {
                          ...this.state.cuponToCreate,
                          limit_use_user: e.target.value,
                        },
                      });
                    }
                  }}
                />

                <Input
                  style={{ marginTop: "1em" }}
                  value={this.state.cuponToCreate.price_discount}
                  inputMode="numeric"
                  placeholder="Valor de descuento %"
                  style={{ width: "100%", marginTop: "1em" }}
                  min={1}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      this.setState({
                        cuponToCreate: {
                          ...this.state.cuponToCreate,
                          price_discount: e.target.value,
                        },
                      });
                    }
                  }}
                />

                <RangePicker
                  style={{ width: "100%", marginTop: "1.5em" }}
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                  onChange={(value) => {
                    this.setState({
                      cuponToCreate: {
                        ...this.state.cuponToCreate,
                        initialCuponDate: value[0].toDate(),
                        finalCuponDate: value[1].toDate(),
                      },
                    });
                  }}
                />
              </div>
            </Row>
          </Modal>

          <div>
            <h1 style={{ marginBottom: 0, marginTop: 0 }}>Cupones</h1>
            <div className="orange-line"></div>
          </div>

          <div style={{ width: "97%", textAlign: "right", marginTop: "-1em" }}>
            <Button onClick={this.showModal} type="primary">
              Agregar Cupon
            </Button>
          </div>

          <Row style={{ marginTop: "1em" }}>
            <Table
              size={`small`}
              className="components-table-demo-nested"
              pagination={false}
              defaultExpandAllRows={true}
              style={{ width: "100%", margin: "1em" }}
              columns={[
                {
                  title: "Codigo del cupon",
                  dataIndex: "code",
                  key: "code",
                },
                {
                  title: "Fecha inicial",
                  dataIndex: "initialCuponDate",
                  key: "initialCuponDate",
                },
                {
                  title: "Fecha final",
                  dataIndex: "finalCuponDate",
                  key: "finalCuponDate",
                },
                {
                  title: "Uso",
                  dataIndex: "limit_use",
                  key: "limit_use",
                },
                {
                  title: "Limite de uso global",
                  dataIndex: "limit",
                  key: "limit",
                },
                {
                  title: "Porcentaje de descuento",
                  dataIndex: "price_discount",
                  key: "price_discount",
                },
                {
                  title: "Veces que lo puede utilizar un usuario",
                  dataIndex: "limit_use_user",
                  key: "limit_use_user",
                },
                {
                  title: "Action",
                  key: "_id",
                  dataIndex: "_id",
                  render: (text, record) => (
                    <div>
                      <Icon
                        type="delete"
                        key="ellipsis"
                        onClick={() => {
                          confirm({
                            title: "¿Estas seguro de eliminar este cupon?",
                            content: "Esta accion no podra rehacerse",
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            onOk: () => {
                              let originalArray = this.state.cupons.filter(
                                (item, position) => {
                                  return item._id != record._id;
                                }
                              );

                              this.setState({
                                cupons: originalArray,
                              });
                              axios
                                .delete(
                                  `${BASE_URL}/super_user/cupon/${record._id}`
                                )
                                .then((response) => {
                                  if (response.status == 200) {
                                    message.success("Cupon eliminado");
                                  }
                                });
                            },
                            onCancel() {
                              console.log("Cancel");
                            },
                          });
                        }}
                      />
                    </div>
                  ),
                },
              ]}
              dataSource={this.state.cupons}
              expandedRowRender={(record) => {
                const columns = [
                  {
                    title: "Fecha",
                    dataIndex: "date",
                    key: "date",
                  },
                  {
                    title: "Monto",
                    dataIndex: "quantity_total",
                    key: "quantity_total",
                  },
                  {
                    title: "Utilizado en el viaje:",
                    dataIndex: "transaction",
                    key: "transaction",
                    render: (text, record) => {
                      console.log(text);
                      return (
                        <div>
                          <p>
                            {text ? (
                              <>
                                <NavLink
                                  to={`/dashboard/location-history/${text}`}
                                >
                                  <a>Ver mas detalles</a>
                                </NavLink>
                              </>
                            ) : (
                              "No se ha utilizado en el viaje"
                            )}
                          </p>
                        </div>
                      );
                    },
                  },
                ];

                return (
                  <Table
                    columns={columns}
                    dataSource={record.usages}
                    pagination={false}
                  />
                );
              }}
              pagination={true}
            />
          </Row>
        </div>
      </div>
    );
  }
}

export default Cupon;
