import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Axios from "axios";
import { BASE_URL } from "../../data/constants";
import Tripitem from "../../components/TripItem";
import CardResumeData from "../../components/CardResumeData";
import { Input, Button, Modal, DatePicker } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";

const { confirm } = Modal;

export class Trips extends Component {
  constructor() {
    super();
    this.state = {
      rides: [],
      ridesToShow: [],
      name_client: "",
      name_conductor: "",
      tripsDone: 0,
      isReset: false,
      todayRetires: 0,
      earnings: 0,
      totalCupons : 0,
      tripsCancelled: 0,
    };

    this.fetchRides = this.fetchRides.bind(this);
  }

  componentDidMount() {
    let day = moment().format("DD");
    let month = moment().month();
    let year = moment().year();

    this.setState({ day, month, year });
    this.fetchRides(day, month, year);
  }

  fetchRides(day, month, year) {
    Axios(`${BASE_URL}/super_user/rides/${day}/${month}/${year}`)
      .then((response) => {
        let total = 0;
        let tripsDone = 0;
        let tripsCancelled = 0;
        let todayRetires = 0;
        let totalCupons = 0

        response.data.map((item) => {
          //console.log(item);

          if (item.status_for_trip == "conductor_finished_ride") {
            tripsDone = tripsDone + 1;
            total = total + item.payment.quantity_company;
            todayRetires = todayRetires + item.payment.quantity_conductor;

            if(item.payment.discount){

              var percentage = (item.payment.discount / 100)
              var discount = (percentage * item.payment.quantity_total)
              var finalPrice = item.payment.quantity_total - discount

              totalCupons = totalCupons + finalPrice
            }

          }

          if (
            item.status_for_trip == "conductor_cancelled" ||
            item.status_for_trip == "client_canceled"
          ) {
            tripsCancelled = tripsCancelled + 1;
          }
        });

        this.setState({
          rides: response.data,
          ridesToShow: response.data,
          totalCupons,
          todayRetires,
          tripsCancelled,
          earnings: total,
          tripsDone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Grid fluid style={{ overflowY: "auto" }}>
        <Row style={{ margin: "2em" }}>
          <Col lg={12}>
            <Row>
              <Col md={1} style={{ marginTop: "0.3em" }}>
                <h1>Viajes</h1>

                <DatePicker
                  placeholder="Filtrar por fecha"
                  defaultValue={moment()}
                  onChange={(value) => {
                    let day = value.format("DD");
                    let month = value.month();
                    let year = value.year();
                    this.setState({ day, month, year });
                    this.fetchRides(day, month, year);
                  }}
                />
              </Col>

              <Row
                style={{
                  marginTop: "1.3em",
                  width: "90%",
                  justifyContent: "flex-end",
                }}
              >
                <Col md={2} style={{ textAlign: "end" }}>
                  <Button
                    icon="plus"
                    type="primary"
                    style={{ marginLeft: "20px" }}
                  >
                    <NavLink
                      to="/dashboard/create-trip"
                      style={{ color: "white" }}
                    >
                      Nuevo Viaje
                    </NavLink>
                  </Button>
                </Col>
              </Row>

              <CardResumeData
                title="Mis ganancias"
                number={this.state.earnings}
              />
              <CardResumeData
                title="Monto cobrado por conductores"
                number={this.state.todayRetires}
              />
              <CardResumeData
                title="Total ventas en viajes"
                number={this.state.earnings + this.state.todayRetires}
              />

              <CardResumeData
                title="Total invertido en cupones"
                number={this.state.totalCupons}
              />
            </Row>

            <hr />

            <Row>
              <CardResumeData
                title="Viajes Terminados"
                type="quantity"
                number={this.state.tripsDone}
              />
              <CardResumeData
                title="Viaje Cancelados"
                type="quantity"
                number={this.state.tripsCancelled}
              />
              <CardResumeData
                type="quantity"
                title="Viajes totales"
                number={this.state.ridesToShow.length}
              />
            </Row>

            <Tripitem
              data={this.state.ridesToShow}
              onDelete={(item) => {
                console.log(item._id);
                confirm({
                  title: "¿Estás seguro de eliminar este viaje?",
                  content: "Esta acción no se podrá deshacer",
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",
                  onOk: () => {
                    Axios.delete(
                      `${BASE_URL}/super_user/transaction/${item._id}`
                    );
                    let newArray = this.state.ridesToShow;

                    newArray = newArray.filter((element) => {
                      return element._id != item._id;
                    });

                    this.setState({ ridesToShow: newArray });
                  },
                });
              }}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Trips;
