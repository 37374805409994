import axios from "axios";
import { API_GOOGLE_KEY } from "../data/constants";


export const getDirectionsFromMaps = (latitude, longitud, cb) => {
  axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitud}&key=${API_GOOGLE_KEY}`
    )
    .then((response) => {
      if (response.status === 200) {
        let data = response.data;
        let results = data.results;

        if (results.length > 0) {
          let address_components = results[0];
          let direction = address_components.formatted_address;
          console.log(direction);
          cb(direction);
          //   this.setState({
          //     storeToRegister: {
          //       ...this.state.storeToRegister,
          //       direction,
          //       coordinates: {
          //         lat: latitude,
          //         lng: longitud,
          //       },
          //     },
          //   });
        }
      }
    });
};


