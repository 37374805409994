export const API_GOOGLE_KEY = "AIzaSyDnygNM2wASuxVYySPl_kM1PFJMot6Vqvk";

export const BASE_URL = "https://geocab-backend.herokuapp.com"

export const CODE_SERVER_VERIFICATION = 34;
export const CODE_SERVER_WRONG_PASSWORD = 35;
export const CODE_SERVER_NO_USER_EXIST = 36;
export const CODE_SERVER_NOT_RESPONDING = 37;
export const CODE_SERVER_VALID_USER = 37;
export const CODE_SERVER_DUPLICATE = 11000;
export const CODE_SERVER_TAXI_NUMBER_DUPLICATE = 5348;
export const CODE_SERVER_NUMBER_PLATE = 4357;
export const CODE_SERVER_EMAIL_DUPLICATE = 3554;
