import React, {Component} from "react";
import {Grid, Row, Col} from "react-flexbox-grid";
import {InputNumber as Input, Button, Modal} from "antd";
import axios from "axios";
import {BASE_URL} from "../../data/constants";
import {firebaseDatabase} from "../../helpers/FirebaseHelper";
import {TimePicker} from "antd";
import moment from "moment";
import {DatePicker, Space} from "antd";
import {Select} from "antd";

const {Option} = Select;


export class Prices extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            loading_taxi: false,
            client: {},
            taxi: {},
            taximetro: {},
            suspentions: {},
        };
    }

    componentDidMount() {

        firebaseDatabase
            .ref("prices/0")
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                    this.setState({
                        client: {
                            ...snapshot.val(),
                        },
                    });
                }
            });

        firebaseDatabase.ref("taximetro")
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                    this.setState({
                        taximetro: {
                            ...snapshot.val(),
                        },
                    });
                }
            });


    }

    render() {
        return (
            <Grid fluid className="h-100" style={{paddingRight: 0, margin: "2em"}}>
                <Row style={{justifyContent: "center"}}>
                    <Col md={5} style={{margin: "1em"}}>
                        <h1>Kilometraje</h1>
                        
                    

                        <div className="container-price-input">
                            <label>Precio por km</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        client: {...this.state.client, price_short_km: value},
                                    });
                                }}
                                value={this.state.client.price_short_km}
                            />
                        </div>

                        <div className="container-price-input">
                            <label>Precio por minuto</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        client: {
                                            ...this.state.client,
                                            time_value: value,
                                        },
                                    });
                                }}
                                value={this.state.client.time_value}
                            />
                        </div>

                        <div className="container-price-input">
                            <label>Tarifa Minima</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        client: {...this.state.client, base_price: value},
                                    });
                                }}
                                value={this.state.client.base_price}
                            />
                        </div>

                       
                    </Col>
                    <Col md={5} style={{margin: "1em"}}>
                        <h1>Taximetro</h1>
                        <div className="container-price-input">
                            <label>Precio por kilometro</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        taximetro: {...this.state.taximetro, km_value: value},
                                    });
                                }}
                                value={this.state.taximetro.km_value}
                            />
                        </div>
                        <div className="container-price-input">
                            <label>Precio por minuto</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        taximetro: {
                                            ...this.state.taximetro,
                                            price_minute: value,
                                        },
                                    });
                                }}
                                value={this.state.taximetro.price_minute}
                            />
                        </div>

                        <div className="container-price-input">
                            <label>Tarifa minima</label>
                            <Input
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    console.log(value);
                                    this.setState({
                                        taximetro: {
                                            ...this.state.taximetro,
                                            base_price: value,
                                        },
                                    });
                                }}
                                value={this.state.taximetro.base_price}
                            />
                        </div>

                    </Col>
                    <Button
                            onClick={() => {
                                this.setState({
                                    loading: true,
                                });

                                console.log({...this.state.client});

                                firebaseDatabase
                                    .ref("prices/0")
                                    .set({
                                        ...this.state.client,
                                    })
                                    .then(() => {
                                        Modal.success({
                                            title: "Precios actualizados",
                                            content: `Se ha actualizado con exito`,
                                        });

                                        this.setState({
                                            loading: false,
                                        });
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            loading: false,
                                        });
                                    });

                                firebaseDatabase
                                    .ref("taximetro")
                                    .set({
                                        ...this.state.taximetro,
                                    })
                                    .then(() => {
                                        Modal.success({
                                            title: "Taximetro actualizado",
                                            content: `Se ha actualizado con exito`,
                                        });

                                        this.setState({
                                            loading: false,
                                        });
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            loading: false,
                                        });
                                    });
                            }}
                            type="primary"
                            block
                            style={{marginTop: "2em", marginLeft : "10em", marginRight : "10em"}}
                            loading={this.state.loading}
                        >
                            Actualizar
                        </Button>
                </Row>
            </Grid>
        );
    }
}

export default Prices;
