import React, { Component } from "react";
import {
  Tabs,
  Radio,
  Card,
  Icon,
  Modal,
  Button,
  Input,
  Select,
  Alert,
  message,
  Avatar,
  Tag,
  Table,
} from "antd";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../data/constants";
import { Row, Col } from "react-flexbox-grid";
import CarTemplate from "../../img/car-template.png";
const { confirm } = Modal;

export class Cars extends Component {
  constructor() {
    super();
    this.state = {
      cars: [],
      carsToShow: [],
      visible: false,
    };

    this.getCurrentCars = this.getCurrentCars.bind(this);
  }

  componentDidMount() {
    this.getCurrentCars();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  getCurrentCars() {
    console.log(`Getting cars`);

    axios
      .get(`${BASE_URL}/super_user/cars`)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.data.result);
          this.setState({
            cars: response.data.result,
            carsToShow: response.data.result,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          margin: "1em",
          overflowY: "auto",
          height: "90%",
        }}
        className="title"
      >
        <div>
          <Modal
            title="Crear un vehiculo"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Row>
              <div className="container-input">
                <label>Modelo del auto</label>
              </div>
            </Row>
          </Modal>

          <div>
            <h1 style={{ marginBottom: 0, marginTop: 0 }}>Autos</h1>
            <div className="orange-line"></div>
          </div>
          {/* 
                    <div style={{ width: "97%", textAlign: "right", marginTop: "-1em" }}>
                        <Button onClick={this.showModal}>Agregar auto</Button>
                    </div> */}
          <Row
            style={{ marginTop: "1em", marginRight: "1.5em", width: "100%" }}
          >
            <Row style={{ marginTop: "1em", marginLeft: "1em", width: "30%" }}>
              <Input
                placeholder="Placas"
                onChange={(e) => {
                  let value = e.target.value;
                  const { cars } = this.state;

                  let filtered = cars.filter((car) => {
                    
                    return car.number_plate
                      .toLowerCase()
                      .includes(value.toLowerCase());
                  });

                  this.setState({ carsToShow: filtered });
                }}
              />
            </Row>
            <Table
              size={`small`}
              style={{ width: "100%", margin: "1em" }}
              columns={[
                {
                  title: "Modelo",
                  key: "model_name",
                  dataIndex: "model_name",
                },
                {
                  title: "Marca",
                  dataIndex: "brand",
                  key: "brand",
                },
                {
                  title: "Año",
                  dataIndex: "year",
                  key: "year",
                },
                {
                  title: "Status",
                  dataIndex: "number_plate",
                  key: "number_plate",
                },
                {
                  title: "Action",
                  key: "_id",
                  dataIndex: "_id",
                  render: (text, record) => (
                    <div>
                      <br />
                      <NavLink to={`/dashboard/auto/${text}`}>
                        Ver detalles
                      </NavLink>
                      <br />
                      <br />
                      <Icon
                        type="delete"
                        key="ellipsis"
                        onClick={() => {
                          confirm({
                            title: "¿Estas seguro de eliminar esta auto?",
                            content: "Esta accion no podra rehacerse",
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            onOk: () => {
                              let originalArray = this.state.cars.filter(
                                (item, position) => {
                                  return item._id != text;
                                }
                              );


                              let originalArray2 = this.state.carsToShow.filter(
                                (item, position) => {
                                  return item._id != text;
                                }
                              );

                              this.setState({
                                cars: originalArray,
                                carsToShow: originalArray2,
                              });

                              console.log(text);

                              axios
                                .delete(`${BASE_URL}/car/${text}`)
                                .then((response) => {
                                  if (response.status == 200) {
                                    message.success("Carro eliminado");
                                  }
                                });
                            },
                            onCancel() {
                              console.log("Cancel");
                            },
                          });
                        }}
                      />
                    </div>
                  ),
                },
              ]}
              dataSource={this.state.carsToShow}
              loading={this.state.loading}
              pagination={true}
            />
          </Row>
        </div>
      </div>
    );
  }
}

export default Cars;
