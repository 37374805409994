import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Alert, Button, Upload } from "antd";
import { firebaseStorage } from "./../../helpers/FirebaseHelper";
import { NavLink } from "react-router-dom";
import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Axios from "axios";
import axios from "axios";
import { BASE_URL } from "../../data/constants";
import CardResumeData from "../../components/CardResumeData";
import {
  Tabs,
  Typography,
  Table,
  Rate,
  DatePicker,
  Select,
  Checkbox,
  Avatar,
  Card,
  Icon,
  List,
  Tag,
  Popconfirm,
  Input,
  Radio,
  message,
  Skeleton,
  Modal,
} from "antd";
import { HorizontalBarGrid } from "../../components/HorizontalBarGrid";
import "../../dashboard.css";
import Tripitem from "../../components/TripItem";
// import { DatePicker } from 'antd';
import moment from "moment-timezone";
moment.locale("es");

const { Dragger } = Upload;
const { Meta } = Card;
const dateFormat = "YYYY/MM/DD";

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;
const ButtonGroup = Button.Group;

// Metodos de nuevo maquetado
const formatter = new Intl.NumberFormat("en-MX", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const DocumentCard = (props) => {
  console.log(props);

  return (
    <Col md={6} style={{ justifyContent: "center", marginTop: "10px" }}>
      <Card
        style={{ width: 300, margin: "1em" }}
        cover={
          <img
            alt="example"
            className="card-img-top image-custom-normal center-cropped"
            src={props.document.url}
          />
        }
        actions={[
          <div>
            <Popconfirm
              title="¿Estas seguro de eliminar este documento?"
              onConfirm={props.onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>,
        ]}
      >
        <Meta title={props.document.name} />
      </Card>
    </Col>
  );
};
const Map = withScriptjs(
  withGoogleMap((props) => {
    let iconMarker = new window.google.maps.MarkerImage(
      Image,
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(100, 100)
    );

    return (
      <GoogleMap center={props.center} defaultZoom={16}>
        {/* {props.conductors.map((location) => {

                    console.log(location.last_location)
                    return (
                        <Marker
                            position={{ lat: location.last_location.lat, lng: location.last_location.lon }}
                            icon={iconMarker}
                            defaultAnimation={window.google.maps.Animation.DROP}
                            onClick={() => {
                                console.log("MARKER IS BEING CLICKED")
                            }}
                        />
                    )
                })} */}

        <Marker position={props.center} icon={iconMarker} />
      </GoogleMap>
    );
  })
);

export class ConductorPage extends Component {
  constructor() {
    super();
    this.state = {
      centerX: [19.418929, -99.107568],
      map: null,
      maps: null,
      mapLoaded: false,
      profile_url: "",
      first_name: "",
      last_name: "",
      email: "",
      loadingModalCLABE: false,
      phoneNumber: "",
      mountToRetire: 0,
      cuota: [],
      loadingData: true,
      moneyToPayCompany: 0,
      url: "",
      loadingModalDocument: false,
      last_connection: "",
      documents: [],
      filesToUpload: [],
      originalData: {},
      cars: [],
      center: { lat: 18.137891, lng: -94.457971 },
      id: "",
      rides: [],
      balance: 0,
      openpay_id: "",
      clabes: [],
      date_created: "",
      titleDocument: "",
      visible: false,
      dateToSearch: Date().toString(),
      status_verification: "",
      isAbleToAdd: false,
      timeConnected: "0h:0m:0s",
      loading: false,
      visibleDocument: false,
      earnings: 0,
      clabe: {},
      currentTab: 1,
      client: [],
      data: [],
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.getTimeLoggedIn = this.getTimeLoggedIn.bind(this);
    this.uploadDocumentToConductor = this.uploadDocumentToConductor.bind(this);
    this.bindEarnings = this.bindEarnings.bind(this);
  }

  getTimeLoggedIn(start, end, isToday) {
    let id = this.props.match.params.id;

    Axios.post(`${BASE_URL}/conductor/login_duration`, {
      id,
      start,
      end,
      isToday: isToday,
    })
      .then((response) => {
        let array = response.data.result;

        let miliseconds = 0;

        console.log(array.length);

        if (array.length > 0) {
          let lastItem = array.find((item, y) => {
            return item.message == "Se ha conectado";
          });

          this.setState({
            timeConnected: "Calculando",
          });

          array.map((item, y) => {
            console.log(moment(item.date).format(dateFormat));

            if (
              item.message == "Se ha desconectado" &&
              lastItem &&
              lastItem.message == "Se ha conectado"
            ) {
              this.setState({
                isAbleToAdd: true,
              });

              let expiresAt = moment(lastItem.date);
              let createdAt = moment(item.date);

              var duration = moment.duration(createdAt.diff(expiresAt));

              console.log("--------");

              console.log(lastItem);
              console.log(item);
              console.log(duration.asMilliseconds());

              miliseconds = duration.asMilliseconds() + miliseconds;

              console.log("--------");
            }

            if (item.message == "Se ha conectado" && this.state.isAbleToAdd) {
              lastItem = item;
              this.setState({
                isAbleToAdd: false,
              });
            }
          });

          //console.log(miliseconds)

          this.setState({
            timeConnected: this.timeConversion(miliseconds),
            dateToSearch: moment(start).toDate(),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  timeConversion(duration) {
    const portions = [];

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
      portions.push(hours + "h");
      duration = duration - hours * msInHour;
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
      portions.push(minutes + "m");
      duration = duration - minutes * msInMinute;
    }

    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
      portions.push(seconds + "s");
    }

    return portions.join(" ");
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    this.setState({ id });

    Axios.post(`${BASE_URL}/transactions/`, {
      token: id,
      type: "conductor",
    }).then((response) => {
      const { rides } = this.state;

      response.data.result.map((transaction) => {
        if (transaction) {
          if (transaction.data) {
            rides.push({ ...transaction.data });
          }
        }
      });

      this.setState({
        rides,
      });

      this.bindEarnings(rides);
    });

    Axios.get(`${BASE_URL}/conductor/payments-cuota/${id}`).then((response) => {
      this.setState({
        cuotas: response.data.result,
      });
    });

    Axios.get(`${BASE_URL}/super_user/conductor/${id}`)
      .then((response) => {
        let data = response.data.conductor;

        if (data.last_location) {
          this.setState({
            center: {
              lat: data.last_location.lat,
              lng: data.last_location.lon,
            },
          });
        }

        this.setState({
          ...response.data.conductor,
          originalData: { ...response.data.conductor },
          documents: data.documents,
          loadingData: false,
        });

        Axios.post(`${BASE_URL}/conductor/clabes`, {
          token: this.state.openpay_id,
        }).then((response) => {
          console.log(response.data.result);
          this.setState({
            clabes: response.data.result,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    this.getTimeLoggedIn(dateObj.toISOString(), dateObj.toISOString(), true);
  }

  bindEarnings(rides) {
    let total = 0.0;
    let mountToRetire = 0.0;
    let moneyToPayCompany = 0.0;

    rides.map((transaction) => {
      total = total + transaction.payment.quantity_conductor;

      console.log(transaction.status_for_trip);

      if (
        !transaction.hasPayedConductor &&
        transaction.status_for_trip == "conductor_finished_ride"
      ) {
        mountToRetire = mountToRetire + transaction.payment.quantity_conductor;
      }

      if (
        !transaction.hasPayedCompany &&
        transaction.status_for_trip == "conductor_finished_ride"
      ) {
        moneyToPayCompany =
          moneyToPayCompany + transaction.payment.quantity_company;
      }
    });

    this.setState({
      earnings: total,
      mountToRetire,
      moneyToPayCompany,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  updateData = (objectToSend) => {
    Axios.post(`${BASE_URL}/conductor/update`, {
      ...objectToSend,
      id: this.props.match.params.id,
    })
      .then(() => {
        message.success("Datos Actualizados");
        this.setState({
          loading: false,
          visible: false,
          originalData: { ...objectToSend },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleOk = (e) => {
    this.setState({
      loading: true,
    });

    const {
      first_name,
      last_name,
      balance,
      email,
      phoneNumber,
      openpay_id,
    } = this.state;

    console.log("HANDLE OK");
    console.log(this.state);

    if (first_name) {
      console.log("HAS FIRST NAME");
      if (last_name) {
        console.log("HAS LAST  NAME");
        if ((balance) => 0) {
          console.log("HAS BALANCE");
          if (email) {
            console.log("EMAIL");
            if (phoneNumber) {
              console.log("HAS PHONE NUMBER");
              if (openpay_id) {
                console.log("HAS OPENPAY");

                let objectToUpdate = {
                  first_name,
                  last_name,
                  balance,
                  email,
                  phoneNumber,
                  openpay_id,
                };

                this.updateData(objectToUpdate);
              }
            }
          }
        }
      }
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      ...this.state.originalData,
    });
  };

  uploadFile(id, file, onImageReady) {
    console.log(file);

    let imagesFilesRef = firebaseStorage.child(`logo/${this.state.id}/${id}`);
    var task = imagesFilesRef.put(file.originFileObj);

    task.on("state_changed", (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      console.log(percentage);
      //this.showProgress(percentage);

      console.log(this.state.filesToUpload);

      if (this.state.filesToUpload) {
        if (this.state.filesToUpload.length) {
          this.state.filesToUpload[0].percent = percentage;
          this.state.filesToUpload[0].status = "uploading";
        }

        this.setState({ filesToUpload: this.state.filesToUpload });
      }
    });

    task.then((child) => {
      imagesFilesRef.getDownloadURL().then((urlSnap) => {
        onImageReady(urlSnap);
        this.setState({
          percentage: 0,
        });

        if (this.state.filesToUpload) {
          if (this.state.filesToUpload.length) {
            this.state.filesToUpload[0].status = "done";
          }

          this.setState({ filesToUpload: this.state.filesToUpload });
        }
      });
    });
  }

  handleImageInput(type, file) {
    let files = [file.file];

    if (FileReader && files && files.length) {
      var fr = new FileReader();

      fr.onload = () => {
        var img = new Image();
        img.src = fr.result;

        img.onload = () => {
          this.uploadFile("logo", file, (url) => {
            this.setState({
              profile_url: url,
              percentage: 0,
            });

            this.updateData({ profile_url: url });
          });
        };
      };

      fr.readAsDataURL(file.originFileObj);
    }
  }

  uploadDocumentToConductor(data) {
    let url = `${BASE_URL}/conductor/documents`;

    Axios.post(`${url}`, {
      ...data,
      isOnlyId: true,
    }).then((response) => {
      const { documents } = this.state;

      documents.push({ ...data.documents, ...response.data });

      this.setState({ documents });

      this.setState({
        filesToUpload: [],
        url: "",
        loadingModalDocument: false,
        visibleDocument: false,
      });
      console.log(response);
    });
  }

  // Metodos de nuevo maquetado
  handleChangeOnDate = (date, dateString) => {
    console.log(date, dateString);
  };

  handleChangeSelect = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      status_verification: value,
    });

    this.updateData({ status_verification: value });
  };

  handleTableChange = (value) => {
    console.log(value);
  };

  render() {
    const convertTime = moment(this.state.last_connection).tz("Etc/GMT-5");
    const convertTimeObject = new Date(convertTime);

    const { changeTable, data, client } = this.state;

    // console.log(this.state);

    return (
      <Grid fluid>
        <Modal
          title="Sube tu documento"
          visible={this.state.visibleDocument}
          confirmLoading={this.state.loadingModalDocument}
          onOk={() => {
            this.setState({
              loadingModalDocument: true,
            });

            if (this.state.titleDocument) {
              this.uploadDocumentToConductor({
                documents: {
                  url: this.state.url,
                  name: this.state.titleDocument,
                  status_verification: "Succesfull",
                },
                token: this.state.id,
              });
            } else {
              Modal.error({
                content: "Se necesita un nombre del documento",
                title: "Error",
              });
            }
          }}
          onCancel={() => {
            this.setState({
              visibleDocument: false,
            });
          }}
        >
          <Input
            placeholder="Nombre del documento"
            style={{ marginBottom: "1em" }}
            onChange={(e) => {
              this.setState({
                titleDocument: e.target.value,
              });
            }}
          />
          <Dragger
            multiple={false}
            accept="image/*"
            customRequest={() => {}}
            fileList={this.state.filesToUpload}
            onChange={(file) => {
              console.log(file.file);
              this.setState({ filesToUpload: [file.file] });
              this.uploadFile(
                `document-${this.state.titleDocument}`,
                file.file,
                (url) => {
                  this.setState({
                    url: url,
                  });
                }
              );
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Dale click o arrastra tu archivo dentro de esta area para subir
            </p>
            <p className="ant-upload-hint">Solo aceptamos archivos png o jpg</p>
          </Dragger>
        </Modal>
        <Modal
          title="Registrar CLABE"
          visible={this.state.visibleCLABE}
          onOk={() => {
            const { clabe } = this.state;
            Axios.post(`${BASE_URL}/conductor/post_clabe`, {
              ...clabe,
              id: this.state.openpay_id,
            })
              .then((response) => {
                const { clabes } = this.state;
                clabes.push(clabe);
                this.setState({ clabes });
                this.setState({
                  loadingModalCLABE: false,
                  visibleCLABE: false,
                });
                message.success({ content: "CLABE registrado con exito" });
              })
              .catch((error) => {
                console.log(error.response.data.description);
                message.error({ content: error.response.data.description });
                this.setState({
                  loadingModalCLABE: false,
                });
              });

            this.setState({
              loadingModalCLABE: true,
            });
          }}
          confirmLoading={this.state.loadingModalCLABE}
          onCancel={() => {
            this.setState({
              visibleCLABE: false,
            });
          }}
        >
          <Row>
            <Col md={12}>
              <label>CLABE</label>
              <Input
                value={this.state.clabe.clabe}
                onChange={(e) => {
                  this.setState({
                    clabe: {
                      ...this.state.clabe,
                      clabe: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>ALIAS</label>
              <Input
                value={this.state.clabe.alias}
                onChange={(e) => {
                  this.setState({
                    clabe: {
                      ...this.state.clabe,
                      alias: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>Nombre del titular</label>
              <Input
                value={this.state.clabe.holder_name}
                onChange={(e) => {
                  this.setState({
                    clabe: {
                      ...this.state.clabe,
                      holder_name: e.target.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </Modal>
        <Row className="p-2--profile">
          <DetailsInformationUser
            state={this.state}
            onChangeInfo={() => {
              this.setState({ visible: true });
            }}
            client={client}
            filesToUpload={this.state.filesToUpload}
            status_verification={this.state.status_verification}
            onPhotoChanged={(file) => {
              this.handleImageInput("logo", file.file);
            }}
            id={this.state.id}
            onDate={this.handleChangeOnDate}
            onSelect={this.handleChangeSelect}
          />
          <Col md={9} style={{ padding: "2em" }}>
            <Tabs
              tabPosition={`top`}
              onChange={(e) => this.setState({ currentTab: parseInt(e) })}
            >
              <TabPane tab="Resumen" key="1">
                <ResumenAccount
                  data={this.state.rides}
                  earnings={this.state.earnings}
                  onTable={this.handleTableChange}
                  moneyToPayCompany={this.state.moneyToPayCompany}
                  mountToRetire={this.state.mountToRetire}
                  loading={changeTable}
                  onDataChange={(value, key, id) => {
                    console.log(id);
                    const { rides } = this.state;
                    let indexToFind = rides.findIndex(
                      (item) => item.id_transaction == id
                    );

                    let object = rides[indexToFind];
                    object[key] = value;
                    rides[indexToFind] = object;

                    axios
                      .put(`${BASE_URL}/transactions/${id}`, {
                        [key]: value,
                        id_transaction: id,
                      })
                      .then((response) => {
                        message.success("Datos actualizados");
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    console.log("CHANGING");
                    console.log(object);
                    this.setState({ rides });
                    this.bindEarnings(rides);
                  }}
                />
              </TabPane>
              <TabPane tab="Cuotas" key="2">
                <Title level={2}>Cuotas Semanal</Title>
                <Table
                  bordered
                  columns={[
                    {
                      title: "ID",
                      dataIndex: "_id",
                    },
                    {
                      title: "Dia inicial de semana",
                      dataIndex: "inital_week",
                    },
                    {
                      title: "Dia final de semana",
                      dataIndex: "end_week",
                    },
                    {
                      title: "Monto",
                      dataIndex: "amount",
                    },
                  ]}
                  rowKey={(record) => record._id}
                  scroll={{ x: "calc(800px + 100%)", y: 800 }}
                  dataSource={this.state.cuotas}
                />
              </TabPane>
              <TabPane tab="CLABES" key="97">
                <Row>
                  <Col xs={6}>
                    <Title level={2}>CLABES</Title>
                  </Col>
                  <Col xs={6}>
                    <Row end="xs">
                      <Col xs={12}>
                        <Button
                          onClick={() => {
                            this.setState({ visibleCLABE: true });
                          }}
                          type="primary"
                        >
                          Agregar CLABE
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Row>
                      {this.state.clabes.map((clabe, index) => {
                        return (
                          <Col md={3} key={index}>
                            <h1>{clabe.clabe}</h1>
                            <p>{clabe.bank_name}</p>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Documentación" key="3">
                <Row>
                  <Col xs={6}>
                    <Title level={2}>Documentos de verificación</Title>
                  </Col>
                  <Col xs={6}>
                    <Row end="xs">
                      <Col xs={12}>
                        <Button
                          onClick={() => {
                            this.setState({ visibleDocument: true });
                          }}
                          type="primary"
                        >
                          Agregar documento
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Row>
                      {this.state.documents.map((document, index) => {
                        return (
                          <Col md={3} key={index}>
                            <DocumentCard
                              onDelete={() => {
                                console.log(document);
                                let currentArray = this.state.documents;

                                currentArray = currentArray.filter((doc) => {
                                  return doc._id !== document._id;
                                });

                                this.setState({
                                  documents: currentArray,
                                });

                                axios
                                  .post(
                                    `${BASE_URL}/super_user/delete_document`,
                                    {
                                      type: "conductor",
                                      id: document._id,
                                      id_user: this.state.id,
                                    }
                                  )
                                  .then((response) => {
                                    console.log(response);
                                  });
                              }}
                              document={document}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Modal
          title="Editar Información"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          confirmLoading={this.state.loading}
          okText="Actualizar"
          cancelText="Cancelar"
        >
          <Row>
            <Col md={12}>
              <label>Nombres</label>
              <Input
                value={this.state.first_name}
                onChange={(e) => {
                  this.setState({ first_name: e.target.value });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>Apellidos</label>
              <Input
                value={this.state.last_name}
                onChange={(e) => {
                  this.setState({ last_name: e.target.value });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>Numero Telefonico</label>
              <Input
                value={this.state.phoneNumber}
                onChange={(e) => {
                  this.setState({ phoneNumber: e.target.value });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>Email</label>
              <Input
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </Col>
            {/* <Col md={12} style={{ marginTop: "2em" }}>
                            <label>Saldo</label>
                            <Input value={this.state.balance} onChange={(e) => {
                                this.setState({ balance: e.target.value })
                            }} />
                        </Col> */}
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>Openpay ID</label>
              <Input
                value={this.state.openpay_id}
                onChange={(e) => {
                  this.setState({ openpay_id: e.target.value });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "2em" }}>
              <label>ID TRANSACTION</label>
              <Input
                value={this.state.current_id_transaction}
                disabled={!this.state.current_id_transaction}
                onChange={(e) => {
                  this.setState({ current_id_transaction: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Modal>
      </Grid>
    );

    // return (
    // 	<div style={{ overflowY: 'auto', height: '98%', width: '100%' }}>

    // 		<Grid fluid className="h-100" style={{ paddingRight: 0 }}>
    // 			<Skeleton loading={this.state.loadingData}>
    // 				<Row
    // 					className="w-100 h-100"
    // 					style={{
    // 						paddingLeft: '4em',
    // 						marginTop: '5em',
    // 						justifyContent: 'center',
    // 						textAlign: 'center',
    // 					}}
    // 				>
    // 					<Col sm={12}>
    // 						<Row style={{ width: '100%' }}>
    // 							<Col sm={7}>
    // 								<Row>
    // 									<div className="update-profile-pic">
    // 										<Upload
    // 											multiple={false}
    // 											accept="image/*"
    // 											customRequest={() => {}}
    // 											fileList={this.state.filesToUpload}
    // 											onChange={(file) => {
    // 												this.handleImageInput('logo', file.file);
    // 											}}
    // 										>
    // 											<Avatar
    // 												size="large"
    // 												style={{ cursor: 'pointer' }}
    // 												icon="user"
    // 												size={100}
    // 												src={this.state.profile_url}
    // 											/>
    // 										</Upload>
    // 									</div>
    // 									<div style={{ marginLeft: '1em', textAlign: 'initial' }}>
    // 										<h3 className={'name'} style={{ marginBottom: '0' }}>
    // 											{this.state.first_name} {this.state.last_name} - $
    // 											{Math.round(this.state.balance * 100) / 100}
    // 										</h3>
    // 										<div style={{ display: 'flex' }}>
    // 											<Icon type="phone" className="mm-1" />
    // 											<p>{this.state.phoneNumber}</p>
    // 										</div>

    // 									</div>
    // 									<Row style={{ marginTop: '2em' }}>
    // 										<Col sm={12}>
    // 											<div style={{ display: 'flex' }}>
    // 												<p>
    // 													Fecha de registro{' '}
    // 													{moment(this.state.date_created).format(
    // 														'dddd, MMMM Do YYYY'
    // 													)}{' '}
    // 													- {this.state.date_created}
    // 												</p>
    // 											</div>
    // 										</Col>
    // 										<Col sm={12}>
    // 											<div style={{ display: 'flex' }}>
    // 												{/* <LocalCarWash /> */}
    // 												<p>
    // 													Ultima Conexión:{' '}
    // 													{moment(convertTimeObject.toString()).format(
    // 														'dddd, MMMM Do YYYY, h:mm:ss a'
    // 													)}
    // 												</p>
    // 											</div>
    // 										</Col>
    // 										<Col sm={12}>
    // 											<div style={{ display: 'flex' }}>
    // 												<p>
    // 													Tiempo conectado{' '}
    // 													{moment(this.state.dateToSearch).format(
    // 														'dddd, MMMM Do YYYY'
    // 													)}{' '}
    // 													- {this.state.timeConnected}
    // 												</p>
    // 											</div>
    // 										</Col>
    // 										<DatePicker
    // 											style={{ marginLeft: '1em' }}
    // 											defaultValue={moment()}
    // 											format={dateFormat}
    // 											onChange={(value) => {
    // 												if (value) {
    // 													var iscurrentDate = value.isSame(new Date(), 'day');

    // 													this.getTimeLoggedIn(
    // 														value.toISOString(),
    // 														value.toISOString(),
    // 														iscurrentDate
    // 													);
    // 												}
    // 											}}
    // 										/>
    // 									</Row>
    // 								</Row>
    // 							</Col>

    // 							<Col md={4} style={{ height: '300px' }}>
    // 								<h2 style={{ textAlign: 'left' }}>Ultima Ubicación </h2>
    // 								<Map
    // 									googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${'AIzaSyDnygNM2wASuxVYySPl_kM1PFJMot6Vqvk'}&libraries=places`}
    // 									loadingElement={<div style={{ height: `95.6%`, width: '100%' }} />}
    // 									containerElement={<div style={{ height: `95.6%`, width: '100%' }} />}
    // 									mapElement={<div style={{ height: `95.6%`, width: '100%' }} />}
    // 									center={this.state.center}
    // 									isMarkerShown={true}
    // 								/>
    // 							</Col>
    // 						</Row>

    // 						{/* <hr style={{ marginRight: "10em", marginTop: "1em" }} /> */}
    // 						{/* <Row style={{ marginRight: "0.5em" }}>
    //                             <h2>Resumen de auditoria </h2>
    //                         </Row>
    //                         <Row>
    //                             <div className={"container-color yellow"}>
    //                                 <h1>Peticiones</h1>
    //                                 <h2>2</h2>
    //                             </div>
    //                             <div className={"container-color red"}>
    //                                 <h1>Buzon de quejas</h1>
    //                                 <h2>2</h2>
    //                             </div>
    //                             <div className={"container-color green"}>
    //                                 <h1>Calificacion</h1>
    //                                 <div style={{ display: "flex", justifyContent: "center" }}>
    //                                     <i className="star"></i>
    //                                     <h2>2</h2>
    //                                 </div>
    //                             </div>
    //                             <div className={"container-color purple"}>
    //                                 <h1>Viajes</h1>
    //                                 <h2>2</h2>
    //                             </div>
    //                         </Row> */}

    // 						<Row
    // 							style={{
    // 								marginRight: '0.5em',
    // 								marginTop: '5em',
    // 								width: '100%',
    // 							}}
    // 						>
    // 							<div
    // 								style={{
    // 									width: '100%',
    // 									textAlign: 'left',
    // 									height: '100px',
    // 								}}
    // 							>
    // 								<h2>Carros Registrados </h2>
    // 								<List
    // 									itemLayout="horizontal"
    // 									dataSource={this.state.cars}
    // 									renderItem={(item) => (
    // 										<List.Item>
    // 											<List.Item.Meta
    // 												title={<a>{item.brand}</a>}
    // 												description={
    // 													<div>
    // 														<h3>
    // 															{item.model_name} {item.year} - Placa:{' '}
    // 															{item.number_plate}
    // 														</h3>
    // 														<h3>{item.direction_location_client}</h3>
    // 													</div>
    // 												}
    // 											/>
    // 										</List.Item>
    // 									)}
    // 								/>
    // 							</div>
    // 						</Row>

    // 						{/* <hr /> */}
    // 						<Row style={{ marginTop: '0m', width: '100%' }}>
    // 							<div
    // 								style={{
    // 									width: '100%',
    // 									textAlign: 'left',
    // 									marginTop: '3em',
    // 								}}
    // 							>
    // 								<h2>Documentos</h2>
    // 								<Button
    // 									type="primary"
    // 									onClick={() => {
    // 										this.setState({
    // 											visibleDocument: true,
    // 										});
    // 									}}
    // 								>
    // 									Agregar documento
    // 								</Button>
    // 								<Col md={3}>
    // 									{this.state.documents.map((document) => {
    // 										return (
    // 											<DocumentCard
    // 												onDelete={() => {
    // 													console.log(document);

    // 													let currentArray = this.state.documents;

    // 													currentArray = currentArray.filter((doc) => {
    // 														return doc._id !== document._id;
    // 													});

    // 													this.setState({
    // 														documents: currentArray,
    // 													});

    // 													axios
    // 														.post(`${BASE_URL}/super_user/delete_document`, {
    // 															type: 'conductor',
    // 															id: document._id,
    // 															id_user: this.state.id,
    // 														})
    // 														.then((response) => {
    // 															console.log(response);
    // 														});
    // 												}}
    // 												document={document}
    // 											/>
    // 										);
    // 									})}
    // 								</Col>
    // 							</div>
    // 						</Row>
    // 						{/* <Row style={{ marginRight: "0.5em", marginTop: "5em", width: "100%" }}>
    //                             <div style={{ width: "100%", textAlign: "left" }}>
    //                                 <h2>Ultimos Recoridos </h2>
    //                                 <Tripitem data={this.state.rides} />
    //                             </div>
    //                         </Row> */}
    // 					</Col>
    // 				</Row>
    // 			</Skeleton>
    // 		</Grid>
    // 	</div>
    // );
  }
}

export default ConductorPage;

const DetailsInformationUser = (props) => {
  const style = {
    paddingAround: { padding: "0 1em" },
    colorWhite: { color: "#fff" },
    bgContainer: { background: "#007aff", height: "100vh" },
    wrapperProfile: { padding: "2em", background: "#3890fe" },
    wrapperScore: { background: "#55a1ff", padding: "1em 0" },
    paddingTitleTimeActive: { padding: "2em 4em 0px" },
    paddingTimeActive: { padding: "1em 0 5em" },
    datePicker: { padding: "1em" },
    paddingTopSelect: { padding: "6em 0 0 " },
  };

  return (
    <Col md={3} style={style.bgContainer}>
      <Row middle="xs" center="md" start="lg" style={style.wrapperProfile}>
        <Col xs style={{ maxWidth: 100, margin: "auto" }}>
          <Upload
            multiple={false}
            accept="image/*"
            customRequest={() => {}}
            fileList={props.filesToUpload}
            onChange={(file) => {
              props.onPhotoChanged(file);
            }}
          >
            <Avatar src={props.state.profile_url} shape="square" size={94} />
          </Upload>
        </Col>
        <Col xs style={style.paddingAround}>
          <Row>
            <Col xs={12}>
              <Title level={4} style={style.colorWhite}>
                {`${props.state.first_name} ${props.state.last_name}`}
              </Title>
            </Col>
            <Col xs={12}>
              <Select
                placeholder="Seleccione una opción"
                value={props.status_verification}
                style={{ width: 148 }}
                onChange={props.onSelect}
              >
                <Option value="Pending">En revisión</Option>
                <Option value="Suspended">Suspendido</Option>
                <Option value="Successful">Verificado</Option>
              </Select>
              <a
                href={`https://admin.aliz.com.mx/password/${props.id}/conductor`}
                target="_blank"
                style={{
                  display: "block",
                  color: "white",
                  paddingLeft: "0.3em",
                  paddingTop: "1em",
                }}
              >
                Cambiar Contraseña
              </a>
              <Button
                type="dashed"
                onClick={() => {
                  props.onChangeInfo();
                }}
              >
                Editar información
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} style={{ padding: "1em 0 0" }}>
          <Row middle="xs">
            <Col xs={12} style={{ marginTop: "1em" }}>
              <p style={style.colorWhite}>
                Ultima conexión:{" "}
                {moment(props.client.last_connection).format(dateFormat)}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row middle="xs">
            <Col xs={12} style={{ padding: 0 }}>
              <p style={style.colorWhite}>
                Inscrito: {moment(props.state.date_created).format(dateFormat)}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row center="xs" middle="xs" style={style.wrapperScore}>
        <Col xs={12}>
          {/* <Row>
            <Col xs={12}>
              <Text style={style.colorWhite}>
                Calificación actual {props.client.score}
              </Text>
            </Col>
            <Col xs={12}>
              <Rate disabled value={props.client.score} />
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Row center="xs" middle="xs">
        {/* <Col xs={12} style={style.datePicker}>
          <Text style={style.colorWhite}>
            Conoce el tiempo activo por fecha
          </Text>
        </Col>
        <Col xs={12} style={style.datePicker}>
          <DatePicker onChange={props.onDate} placeholder="Selecciona fecha" />
        </Col> */}
        {/* <Col xs={12} style={style.paddingTitleTimeActive}>
          <Text style={style.colorWhite}>Tiempo conectado</Text>
        </Col>
        <Col xs={12} style={style.paddingTimeActive}>
          <Title style={style.colorWhite}>{props.state.timeConnected}</Title>
        </Col> */}
      </Row>
    </Col>
  );
};

const ResumenAccount = (props) => {
  console.log(props);
  const columns = [
    {
      title: "ID",
      dataIndex: "id_transaction",
    },
    {
      title: "Ganancia Conductor",
      render: (e, item) => formatter.format(item.payment.quantity_conductor),
    },
    {
      title: "Status del viaje",
      render: (e, item) => item.status_for_trip,
    },
    {
      title: "Ganancia Empresa",
      render: (e, item) => formatter.format(item.payment.quantity_company),
    },
    {
      title: "Cupon de descuento",
      render: (e, item) =>
        formatter.format(item.payment.discount ? item.payment.discount : 0),
    },
    {
      title: "Fecha de servicio",
      dataIndex: "date",
    },
    {
      title: "Forma de pago",
      dataIndex: "gain",
      render: (e, item) => {
        let payment = "";

        if (item.payment.type_of_payment == 20) {
          payment = "DEBITO";
        } else if (item.payment.type_of_payment == 23) {
          payment = "Efectivo";
        } else if (item.payment.type_of_payment == 55) {
          payment = "Aliz Empresa";
        }

        return <p>{payment}</p>;
      },
    },
    {
      title: "Detalles del viaje:",
      dataIndex: "id_transaction",
      key: "id_transaction",
      render: (text, record) => {
        console.log(text);
        return (
          <div>
            <p>
              {text ? (
                <>
                  <NavLink to={`/dashboard/location-history/${text}`}>
                    <a>Ver mas detalles</a>
                  </NavLink>
                </>
              ) : (
                "No se ha utilizado en el viaje"
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: "Detalles de pago a conductor",
      dataIndex: "hasPayedConductor",
      render: (e, item) => {
        let showCheckBox = true;

        if (item.payment.type_of_payment == 23) {
          showCheckBox = false;
        }

        if (item.status_for_trip !== "conductor_finished_ride") {
          showCheckBox = false;
        }

        return (
          <div>
            {showCheckBox ? (
              <Checkbox
                checked={item.hasPayedConductor}
                onChange={(value) => {
                  props.onDataChange(
                    value.target.checked,
                    "hasPayedConductor",
                    item.id_transaction
                  );
                }}
              >
                Pagado
              </Checkbox>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Detalles de pago a empresa (comission)",
      dataIndex: "hasPayedCompany",
      render: (e, item) => {
        let showCheckBox = true;

        if (item.payment.type_of_payment == 20) {
          showCheckBox = false;
        }


        return (
          <div>
            {showCheckBox ? (
              <Checkbox
                onChange={(value) => {
                  props.onDataChange(
                    value.target.checked,
                    "hasPayedCompany",
                    item.id_transaction
                  );
                }}
                checked={item.hasPayedCompany}
              >
                Pagado
              </Checkbox>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const style = {
    paddingTop: { padding: "1em 0 0 0" },
    marginTop: { margin: "1em 0 0 0" },
  };

  return (
    <Row className="m-none">
      <Col xs={12}>
        <Row>
          <CardResumeData
            title="Servicios realizados"
            number={props.data.length}
            type="quantity"
          />
          <CardResumeData
            title="Total ganancias del conductor"
            number={props.earnings}
          />
          <CardResumeData
            title="Dinero por retirar"
            number={props.mountToRetire}
          />
          <CardResumeData
            title="Dinero por pagar a empresa"
            number={props.moneyToPayCompany}
          />
          {/* <CardResumeData title="Deudas" number={props.earnings} /> */}
        </Row>
        <Row style={style.paddingTop}>
          <Col xs={12} style={style.marginTop}>
            <Title level={3}>Historial de servicios</Title>
          </Col>
          <Col xs={12}>
            <Table
              bordered
              columns={columns}
              rowKey={(record) => record._id}
              scroll={{ x: "calc(800px + 100%)", y: 800 }}
              dataSource={props.data}
              loading={props.loading}
              onChange={props.onTable}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
