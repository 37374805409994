import React, { Component } from 'react';
import Navbar from '../../components/Navbar';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Map from '../private/Map';
import User from '../private/Users';
import ConductorView from '../private/ConductorPage';
import HistoryLocation from '../private/HistoryLocation';
import Verification from './Verification';
import Trips from './Trips';
import ClientProfile from './ClientProfile';
import EmergenciesPage from '../private/Emergencies';
import Logs from '../private/Logs';
import Prices from './Prices';
import { Alert } from '../private/Alert';
import Stores from '../private/Store';
import Cars from './Cars';
import CarPage from './CarPage';
import StoreProfile from './StoreProfile';
import CreateRide from './CreateRide';
import Cupon from './Cupon';

export class Dashboard extends Component {
	render() {
		return (
			<div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
				<Navbar />
				<Switch>
					<Route path="/dashboard/users/:type" component={User} />
					<Route path="/dashboard/conductor/:id" component={ConductorView} />
					<Route path="/dashboard/location-history/:id" component={HistoryLocation} />
					<Route path="/dashboard/client/:id" component={ClientProfile} />
					<Route path="/dashboard/verification" component={Verification} />
					<Route path="/dashboard/prices" component={Prices} />
					<Route path="/dashboard/cupons" component={Cupon} />
					<Route path="/dashboard/trips" component={Trips} />
					<Route path="/dashboard/auto/:id" component={CarPage} />
					<Route path="/dashboard/create-trip/:key?" component={CreateRide} />
					<Route path="/dashboard/store/:id" component={StoreProfile} />
					<Route path="/dashboard/emergencias" component={EmergenciesPage} />
					<Route path="/dashboard/alertas" component={Alert} />
					<Route path="/dashboard/tiendas" component={Stores} />
					<Route path="/dashboard/autos" component={Cars} />
					<Route path="/dashboard/logs" component={Logs} />
					<Route path="/dashboard/" component={Map} />
				</Switch>
			</div>
		);
	}
}

export default Dashboard;
