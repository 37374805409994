import React, { Component } from "react";
import { Avatar, Card, Icon, List, Tag } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

export class TripItem extends Component {
  componentDidMount() {
    moment.locale("es");
  }

  render() {
    let list = this.props.data;
    console.log(list);
    return (
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => {
          let text = "";
          let color = "";

          switch (item.status_for_trip) {
            case "conductor_cancelled": {
              color = "red";
              text = "Conductor Cancelo el Viaje";
              break;
            }

            case "conductor_finished_ride": {
              color = "green";
              text = "Viaje Terminado";
              break;
            }

            case "conductor_accepted": {
              color = "blue";
              text = "Conductor Acepto el viaje";
              break;
            }

            case "conductor_arrived": {
              color = "orange";
              text = "Conductor En espera";
              break;
            }

            case "conductor_driving_destination": {
              color = "purple";
              text = "El conductor va viajando al destino";

              break;
            }

            case "client_canceled": {
              color = "red";
              text = "Cliente cancelo el viaje";
              break;
            }

            default: {
            }
          }

          return (
            <List.Item
              extra={
                <div style={{ width: "auto", textAlign: "center" }}>
                  <NavLink to={`/dashboard/location-history/${item._id}`}>
                    <a>Ver mas detalles</a>
                  </NavLink>
                  <br />
                  <a
                    onClick={() => {
                      if (this.props.onDelete) {
                        this.props.onDelete(item);
                      }
                    }}
                  >
                    Eliminar
                  </a>
                </div>
              }
            >
              <List.Item.Meta
                title={
                  <a>
                    {moment(item.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </a>
                }
                description={
                  <div>
                    <Tag style={{ marginTop: "0.5em" }} color={color}>
                      {text}
                    </Tag>

                    <br />

                    {item.payment.discount > 0 ? (
                      <Tag style={{ marginTop: "1em" }} color="#f50">
                        Aplico un cupon de descuento {item.payment.discount} %
                      </Tag>
                    ) : null}

                    {item.client ? (
                      <h3 style={{ marginTop: "0.5em" }}>
                        Cliente : {item.client.first_name}{" "}
                        {item.client.last_name} - ID: {item.client._id}
                      </h3>
                    ) : (
                      ""
                    )}
                    {item.conductor.first_name ? (
                      <NavLink
                        to={`/dashboard/conductor/${item.conductor._id}`}
                      >
                        Conductor : {item.conductor.first_name}{" "}
                        {item.conductor.last_name}
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {console.log(item)}
                    <h3>{item.direction_location_client}</h3>
                    <Icon type="down" />
                    <h3 style={{ marginTop: "0.5em" }}>
                      {item.direction_destination}
                    </h3>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  }
}

export default TripItem;
