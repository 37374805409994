import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../data/constants";
import { Grid, Row, Col } from "react-flexbox-grid";
import {
  firebaseDatabase,
  firebaseStorage,
} from "../../helpers/FirebaseHelper";
import {
  Avatar,
  Card,
  Icon,
  List,
  Tag,
  Upload,
  Input,
  message,
  AutoComplete,
  Skeleton,
  Modal,
  Button,
  Radio,
  Select,
} from "antd";
import CarTemplate from "../../img/car-template.png";
import moment from "moment-timezone";
moment.locale("es");

const { Option } = Select;

export class CarPage extends Component {
  constructor() {
    super();
    this.state = {
      conductors: [],
      car: {},
      condcutors: [],
      cars_models: [],
      indexCategory: -1,
      indexModel: -1,
      showModalConductorAssignment: false,
      loading_car_assignment: false,
      loading_car_info: false,
      visible: false,
    };

    this.getCarInfo = this.getCarInfo.bind(this);
    this.getConductorsUsingCar = this.getConductorsUsingCar.bind(this);
    this.getCarModels = this.getCarModels.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalConductorAssignment = () => {
    this.setState({
      showModalConductorAssignment: true,
    });
  };

  handleOk = (e) => {
    console.log(e);

    this.setState({
      loading_car_info: true,
    });

    const { car } = this.state;
    axios
      .put(`${BASE_URL}/car/${car._id}`, {
        ...this.state.car,
      })
      .then((response) => {
        this.setState({
          visible: false,
        });
        message.success("Datos actualizados");
      })
      .catch((error) => {
        message.error("Ha ocurrido un error con los servidores");
      });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      showModalConductorAssignment: false,
    });
  };

  getCarModels() {
    firebaseDatabase
      .ref("cars")
      .once("value")
      .then((response) => {
        let cars_models = response.val().brands;

        this.setState({
          cars_models,
        });

        let brand = this.state.car.brand;
        let model_name = this.state.car.model_name;

        let indexCategory = -1;
        let indexModel = -1;

        cars_models.filter((item, index) => {
          if (item.name == brand) {
            indexCategory = index;
            item.models.filter((innerItem, y) => {
              if (innerItem.name == model_name) {
                indexModel = y;
              }
            });
          }
          return item.name.includes(brand);
        });

        this.setState({ indexCategory, indexModel });
        console.log(cars_models);
      });
  }

  getCarInfo() {
    let id = this.props.match.params.id;

    axios
      .get(`${BASE_URL}/super_user/car/${id}`)
      .then((response) => {
        console.log(response);
        this.getCarModels();
        let type = "classic";

        let dataCar = response.data.result;

        if (dataCar.type) {
          type = dataCar.type;
        }

        this.setState({
          car: {
            ...response.data.result,
            type,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getConductorsUsingCar() {
    let id = this.props.match.params.id;

    axios
      .get(`${BASE_URL}/super_user/conductors_with_car/${id}`)
      .then((response) => {
        console.log(response);
        this.setState({
          conductors: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getCarInfo();
    this.getConductorsUsingCar();
  }

  uploadFile(id, file, onImageReady) {
    console.log(file);

    let imagesFilesRef = firebaseStorage.child(`logo/${this.state.id}/${id}`);
    var task = imagesFilesRef.put(file.originFileObj);

    task.on("state_changed", (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(percentage);
    });

    task.then((child) => {
      imagesFilesRef.getDownloadURL().then((urlSnap) => {
        onImageReady(urlSnap);
        this.setState({
          percentage: 0,
        });
      });
    });
  }

  handleImageInput(type, file) {
    let files = [file.file];

    if (FileReader && files && files.length) {
      var fr = new FileReader();

      fr.onload = () => {
        var img = new Image();
        img.src = fr.result;

        const { car } = this.state;

        img.onload = () => {
          this.uploadFile(car._id, file, (url) => {
            console.log(url);
            this.setState({
              car: {
                ...this.state.car,
                model_url: url,
              },
            });

            axios
              .put(`${BASE_URL}/car/${car._id}`, {
                ...this.state.car,
              })
              .then((response) => {
                message.success("Datos actualizados");
              })
              .catch((error) => {
                message.error("Ha ocurrido un error con los servidores");
              });

            //this.updateData({ profile_url: url });
          });
        };
      };

      fr.readAsDataURL(file.originFileObj);
    }
  }

  render() {
    let urlCar = CarTemplate;

    if (this.state.car.model_url) {
      urlCar = this.state.car.model_url;
    }

    let options = [];

    if (this.state.indexCategory >= 0) {
      options = this.state.cars_models[this.state.indexCategory].models;
    }

    return (
      <Grid fluid className="h-100" style={{ paddingRight: 0 }}>
        <Modal
          title="Asignar auto a conductor"
          visible={this.state.showModalConductorAssignment}
          confirmLoading={this.state.loading_car_assignment}
          onOk={this.handleOkConductor}
          onCancel={this.handleCancel}
        >
          <Row>
            <div className="container-input">
              <label>Nombre del conductor</label>
              <AutoComplete
                style={{ width: 200 }}
                onSearch={this.handleSearch}
                placeholder="input here"
              >
                {["", "", ""].map((item) => {
                  return <Option key={1}>1</Option>;
                })}
              </AutoComplete>
            </div>
          </Row>
        </Modal>
        <Modal
          title="Modificar vehiculo"
          visible={this.state.visible}
          confirmLoading={this.state.loading_car_info}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row>
            <div className="container-input">
              <label>Marca del auto</label>
              <Input
                value={this.state.car.brand}
                style={{ width: "100%" }}
                onChange={(e) => {
                  let value = e.target.value;

                  const { car } = this.state;
                  car["brand"] = value;

                  this.setState({
                    car,
                  });
                  console.log(car);
                }}
              >
                {this.state.cars_models.map((brand) => {
                  return <Option value={brand.name}>{brand.name}</Option>;
                })}
              </Input>
            </div>
            <div className="container-input">
              <label>Modelo del auto</label>
              <Input
                style={{ width: "100%" }}
                value={this.state.car.model_name}
                onChange={(value) => {
                  console.log(value);
                  const { car } = this.state;

                  car["model_name"] = value;

                  this.setState({
                    car,
                  });
                  console.log(car);
                }}
              >
                {options.map((model) => {
                  return <Option value={model.name}>{model.name}</Option>;
                })}
              </Input>
            </div>
            <div className="container-input">
              <label>Año del auto</label>
              <Select
                style={{ width: "100%" }}
                value={parseInt(this.state.car.year)}
                onChange={(e) => {
                  let value = e;

                  const { car } = this.state;

                  car["year"] = value;

                  this.setState({ car });
                }}
              >
                <Option value={2020}>2020</Option>
                <Option value={2019}>2019</Option>
                <Option value={2018}>2018</Option>
                <Option value={2017}>2017</Option>
                <Option value={2016}>2016</Option>
                <Option value={2015}>2015</Option>
                <Option value={2014}>2014</Option>
                <Option value={2013}>2013</Option>
                <Option value={2012}>2012</Option>
                <Option value={2011}>2011</Option>
              </Select>
            </div>
            <div className="container-input">
              <label>Placa del auto</label>
              <Input
                onChange={(e) => {
                  let value = e.target.value;

                  const { car } = this.state;

                  car["number_plate"] = value;

                  this.setState({
                    car,
                  });
                }}
                value={this.state.car.number_plate}
              />
            </div>
          </Row>
        </Modal>
        <Skeleton loading={this.state.loadingData}>
          <Row
            className="w-100 h-100"
            style={{
              paddingLeft: "4em",
              marginTop: "5em",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Col sm={12}>
              <Row>
                <Col sm={9}>
                  <Row>
                    <div>
                      <Upload
                        multiple={false}
                        accept="image/*"
                        customRequest={() => {}}
                        fileList={this.state.filesToUpload}
                        onChange={(file) => {
                          this.handleImageInput("car", file.file);
                        }}
                      >
                        <img
                          src={urlCar}
                          alt=""
                          style={{ cursor: "pointer" }}
                          height={150}
                          onError={() => {
                            urlCar = CarTemplate;
                          }}
                        />
                      </Upload>

                      <br />
                      <Tag
                        color={`${
                          this.state.car.status_verification == "Pending"
                            ? "#d50000"
                            : "#4caf50"
                        }`}
                      >
                        {this.state.car.status_verification == "Pending"
                          ? "Pendiente"
                          : "Validado"}
                      </Tag>
                      <br />
                      <Radio.Group
                        onChange={(e) => {
                          console.log(e.target.value);

                          const { car } = this.state;

                          car["status_verification"] = e.target.value;

                          this.setState({
                            car,
                          });

                          axios
                            .put(`${BASE_URL}/car/${car._id}`, {
                              ...this.state.car,
                            })
                            .then((response) => {
                              message.success("Datos actualizados");
                            })
                            .catch((error) => {
                              message.error(
                                "Ha ocurrido un error con los servidores"
                              );
                            });
                        }}
                        value={this.state.car.status_verification}
                        style={{ marginTop: "20px" }}
                      >
                        <Radio value={"Successful"}>Validado</Radio>
                        <Radio value={"Pending"}>Desactivado</Radio>
                      </Radio.Group>
                    </div>

                    <div
                      style={{
                        marginLeft: "1em",
                        textAlign: "initial",
                        marginTop: "2em",
                        marginLeft: "2em",
                      }}
                    >
                      <h3 className={"name"} style={{ marginBottom: "5px" }}>
                        {this.state.car.brand} {this.state.car.model_name} -{" "}
                        {this.state.car.year}
                      </h3>
                      <h2 className={"name"} style={{ marginBottom: "5px" }}>
                        {this.state.car.number_plate}
                      </h2>
                      <Button
                        type="primary"
                        onClick={this.showModal}
                        style={{ marginRight: "1em" }}
                      >
                        Editar información
                      </Button>
                      {/* <Button
                        type="dashed"
                        onClick={this.showModalConductorAssignment}
                        style={{ margin: "1em" }}
                      >
                        Asignar Auto a conductor
                      </Button> */}
                    </div>
                  </Row>
                </Col>

                {/* <Col sm={2} style={{ textAlign: "end" }}>
                                    <NavLink to="/dashboard/location-history/:id">
                                        <Button>Consultar Recorrido</Button>
                                    </NavLink>
                                </Col> */}
              </Row>

              <Row style={{ marginTop: "2em" }}></Row>
              {/* <Row style={{ marginRight: "0.5em" }}>
                                <h2>Resumen de auditoria </h2>
                            </Row>
                            <Row>
                                <div className={"container-color yellow"}>
                                    <h1>Peticiones</h1>
                                    <h2>2</h2>
                                </div>
                                <div className={"container-color red"}>
                                    <h1>Buzon de quejas</h1>
                                    <h2>2</h2>
                                </div>
                                <div className={"container-color green"}>
                                    <h1>Calificacion</h1>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <i className="star"></i>
                                        <h2>2</h2>
                                    </div>
                                </div>
                                <div className={"container-color purple"}>
                                    <h1>Viajes</h1>
                                    <h2>2</h2>
                                </div>
                            </Row> */}

              <Row
                style={{
                  marginRight: "0.5em",
                  marginTop: "5em",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", textAlign: "left" }}>
                  <h2>Conductores usando este auto </h2>
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.conductors}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={item.profile_url}
                              alt="Profile picture
                                                            "
                            />
                          }
                          title={<a>{item.phoneNumber}</a>}
                          description={
                            <div>
                              <h3>{item.first_name}</h3>
                              <h3>Ultima Conexión : {item.last_connection}</h3>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Row>

              <hr />
              {/* <Row style={{ marginRight: "0.5em", marginTop: "5em", width: "100%" }}>
                                <div style={{ width: "100%", textAlign: "left" }}>
                                    <h2>Ultimos Recoridos </h2>
                                    <Tripitem data={this.state.rides} />
                                </div>
                            </Row> */}
            </Col>
          </Row>
        </Skeleton>
      </Grid>
    );
  }
}

export default CarPage;
