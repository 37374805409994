import React, { Component } from "react";
import { Grid, Col, Row } from "react-flexbox-grid";
import {
  Avatar,
  Alert,
  Radio,
  Input,
  Button,
  Tabs,
  Card,
  Icon,
  Popconfirm,
  message,
  Modal,
  Table,
} from "antd";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../data/constants";
import axios from "axios";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Meta } = Card;

const success = () => {
  message.success("Usuario Notificado");
};

const DocumentCard = (props) => {
  console.log(props);

  return (
    <Col md={6} style={{ justifyContent: "center", marginTop: "10px" }}>
      <Card
        style={{ width: 300, margin: "1em" }}
        cover={
          <img
            alt="example"
            className="card-img-top image-custom-normal center-cropped"
            src={props.document.url}
          />
        }
        actions={[
          <div>
            <Popconfirm
              title="¿Estas seguro de eliminar este documento?"
              onConfirm={props.onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>,
        ]}
      >
        <Meta title={props.document.name} />
      </Card>
      <Radio.Group
        buttonStyle="solid"
        style={{ marginLeft: "2em" }}
        onChange={(e) => {
          let value = e.target.value;
          let status = "";

          if (value === 0) {
            status = "Rejected";
          } else {
            status = "Successful";
          }

          axios
            .post(`${BASE_URL}/super_user/status_document`, {
              id: props.document._id,
              status,
            })
            .then((response) => {
              console.log(response);
            });
        }}
      >
        <Radio.Button value={1}>Aceptar</Radio.Button>
        <Radio.Button value={0}>Rechazar</Radio.Button>
      </Radio.Group>
    </Col>
  );
};

export class Verification extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      conductors: [],
      conductorsToShow: [],
      isConductor: false,
      currentUser: null,
      visible: false,
    };
  }

  componentDidMount() {
    // axios.get(`${BASE_URL}/super_user/get_clients`).then((response) => {
    //   if (response.status === 200) {
    //     this.setState({
    //       users: response.data.result,
    //     });
    //   }
    // });

    axios.get(`${BASE_URL}/super_user/get_conductors`).then((response) => {
      if (response.status === 200) {
        this.setState({
          conductors: response.data.result,
          conductorsToShow: response.data.result,
        });
      }
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Grid
        fluid
        style={{ marginLeft: "1em", marginTop: "1em", marginLeft: "2em" }}
      >
        <Modal
          title="Verificación"
          visible={this.state.visible}
          width={1000}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row>
            <Col md={9}>
              <div className="w-100 h-100 d-flex">
                <div className="black-line-2" />
                {this.state.currentUser ? (
                  <div
                    style={{
                      padding: "1em",
                      overflowY: "scroll",
                      height: "800px",
                      width: "100%",
                      marginBottom: "4em",
                    }}
                  >
                    <h1 style={{ margin: 0 }}>
                      {this.state.currentUser.first_name}{" "}
                      {this.state.currentUser.last_name}
                    </h1>
                    <div className="d-flex">
                      <p>ID : {this.state.currentUser._id}</p>
                      <p style={{ marginLeft: "2em" }}>
                        Fecha : {this.state.currentUser.date_created}
                      </p>
                    </div>
                    <Row style={{ marginTop: "0px", marginLeft: "0.2em" }}>
                      <div style={{ display: "flex", marginBottom: "0.7em" }}>
                        <Button
                          onClick={() => {
                            axios
                              .post(
                                `${BASE_URL}/super_user/send_notification_alert`,
                                { id: this.state.currentUser._id }
                              )
                              .then((response) => {
                                success();
                              });
                          }}
                          type="danger"
                          style={{ marginRight: "1em" }}
                        >
                          Enviar Alerta de verificacion
                        </Button>

                        <Button
                          onClick={() => {
                            axios
                              .post(
                                `${BASE_URL}/super_user/send_notification`,
                                { id: this.state.currentUser._id }
                              )
                              .then((response) => {
                                success();
                              });
                          }}
                          type="primary"
                        >
                          Enviar Alerta de exito
                        </Button>
                      </div>
                    </Row>

                    <Row style={{ marginTop: "1px" }}>
                      <Col md={7}>
                        <h1 style={{ marginBottom: 0 }}>
                          Datos de comunicación
                        </h1>
                        <p>
                          Numero Telefonico:{" "}
                          {this.state.currentUser.phoneNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <Col md={12}>
                        <h1 style={{ marginBottom: 0 }}>
                          Documentos por verificar
                        </h1>
                        <Row>
                          {this.state.currentUser.documents.length ? null : (
                            <h2 style={{ marginLeft: "0.5em" }}>
                              Sin documentos anexados
                            </h2>
                          )}
                          {this.state.currentUser.documents.map((document) => {
                            return (
                              <DocumentCard
                                document={document}
                                onDelete={() => {
                                  console.log(document);

                                  let currentArray = this.state.currentUser
                                    .documents;

                                  currentArray = currentArray.filter((doc) => {
                                    return doc._id !== document._id;
                                  });

                                  const { currentUser } = this.state;

                                  currentUser.documents = currentArray;

                                  this.setState({
                                    currentUser,
                                  });

                                  axios
                                    .post(
                                      `${BASE_URL}/super_user/delete_document`,
                                      {
                                        type: "conductor",
                                        id: document._id,
                                        id_user: this.state.currentUser._id,
                                      }
                                    )
                                    .then((response) => {
                                      console.log(response);
                                    });
                                }}
                              />
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Modal>
        <Tabs
          defaultActiveKey="1"
          onChange={(value) => {
            this.setState({
              isConductor: parseInt(value) == 2,
            });
          }}
        >
          <TabPane tab="Conductores" key="2"></TabPane>
        </Tabs>
        <Input
          style={{ width: "50%", marginBottom: "2em" }}
          placeholder="Nombre del conductor"
          onChange={(e) => {
            let value = e.target.value;
            let filterArray = this.state.conductors.filter((item) => {
              let fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
              return fullName.includes(value.toLowerCase());
            });

            this.setState({
              conductorsToShow: filterArray,
            });
          }}
        />
        <Table
          dataSource={this.state.conductorsToShow}
          columns={[
            {
              title: "Nombre",
              key: "name",
              render: (text, fullObject, y) => {
                return (
                  <td>
                    {fullObject.first_name} {fullObject.last_name}
                  </td>
                );
              },
            },
            {
              title: "Correo Electronico",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Numero Telefonico",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
            },
            {
              title: "Status",
              dataIndex: "status_verification",
              key: "status_verification",
            },
            {
              title: "Action",
              key: "_id",
              render: (text, record) => (
                <div size="middle">
                  <a
                    onClick={() => {
                      console.log(record);
                      this.setState({ currentUser: record, visible: true });
                    }}
                  >
                    Ver detalles
                  </a>
                  <br />
                  <a
                    onClick={() => {
                      const { conductors, conductorsToShow } = this.state;

                      let filter = conductors.filter(
                        (item) => item._id != text._id
                      );

                      let filter2 = conductorsToShow.filter(
                        (item) => item._id != text._id
                      );

                      this.setState({
                        conductors: filter,
                        conductorsToShow: filter2,
                      });

                      axios
                        .delete(`${BASE_URL}/super_user/conductor/${text._id}`)
                        .then((response) => {
                          if (response.status == 200) {
                            message.success("Conductor eliminado");
                          }
                        });
                    }}
                  >
                    Eliminar Usuario
                  </a>
                </div>
              ),
            },
          ]}
        />
        ;
      </Grid>
    );
  }
}

export default Verification;
