import React, { Component } from "react";
import GoogleMap from "google-map-react";
import { Grid, Row, Col } from "react-flexbox-grid";
import {
  Table,
  Divider,
  Tag,
  Modal,
  Avatar,
  Button,
  AutoComplete as Input,
  Input as EditText,
} from "antd";
import { Select } from "antd";

import { fitBounds } from "google-map-react/utils";
import { BASE_URL } from "../../data/constants";
import Axios from "axios";
import Autocomplete from "react-google-autocomplete";
import { Checkbox } from "antd";
import ServerHelper from "../../helpers/ServerHelper";
import { firebaseDatabase } from "../../helpers/FirebaseHelper";
import imageCar from "../../img/markers/car_marker.png";
import moment from "moment";
import "moment/locale/es";

import { getDirectionsFromMaps } from "../../helpers/ServerHelper";

const API_GOOGLE_KEY = "AIzaSyDDO9EbDTQCSSfn2f00MjajI7gV3ied6xI";
const { Option } = Input;

export class CreateRide extends Component {
  constructor() {
    super();
    this.state = {
      centerX: [19.418929, -99.107568],
      map: null,
      maps: null,
      mapLoaded: false,
      markerOrigin: null,
      markerDestination: null,
      isMarkerOrigin: false,
      isMarkerDestination: false,
      conductors: [],
      directionsDisplay: null,
      clients: [],
      direction_location_client: "",
      currentConductor: null,
      directionDestination: "",
      km: 0,
      minutes: 0,
      transaction: null,
      price: 0,
      phoneNumber: "",
      settings: null,
      name: "",
      origin_location_client: null,
      destination_location: null,
      conductor: null,
      client: null,
      status: "pending",
    };

    this.checkIfTwoLocationsAreLoaded = this.checkIfTwoLocationsAreLoaded.bind(
      this
    );

    this.createTransaction = this.createTransaction.bind(this);
    this.getOnlineConductors = this.getOnlineConductors.bind(this);
  }

  componentDidMount() {
    Axios.get(`${BASE_URL}/client/`)
      .then((response) => {
        let array = response.data.result.filter((user) => {
          return user.first_name;
        });

        this.setState({
          clients: array,
        });
      })
      .catch((error) => {});

    let id = this.props.match.params.key;

    console.log(id);
    if (id) {
      firebaseDatabase
        .ref("logs")
        .child(id)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
            this.setState({
              directionDestination: snapshot.val().direction_destination,
              direction_location_client: snapshot.val()
                .direction_location_client,
              name: snapshot.val().client.first_name,
              destination_location: {
                lat: snapshot.val().destination.lat,
                lng: snapshot.val().destination.lon,
              },
              origin_location_client: {
                lat: snapshot.val().origin_location.lat,
                lng: snapshot.val().origin_location.lon,
              },
              phoneNumber: snapshot.val().client.phoneNumber,
            });

            setTimeout(() => {
              console.log(this.state);
              this.checkIfTwoLocationsAreLoaded();
            }, 1000);
          }
        });
    }

    firebaseDatabase
      .ref("prices/0")
      .once("value")
      .then((snapshot) => {
        this.setState({
          settings: snapshot.val(),
        });
      });

    this.getOnlineConductors();
  }

  getOnlineConductors() {
    firebaseDatabase.ref("online").on("child_added", (snapshot) => {
      const { maps, map } = this.state;

      const { conductors } = this.state;

      console.log("NEW USER ADDED");

      let value = { ...snapshot.val() };

      if (true) {
        value = {
          ...value,
          // last_location: {
          //   lat: value.location.latitude,
          //   lng: value.location.longitude,
          // },
        };

        console.log(value);

        conductors.push({
          ...value,
          key: snapshot.key,
          indexToIterate: 0,
          deltaLat: null,
          deltaLng: null,
          indexAnimation: 0,
          isOffline: false,
        });

        if (value.last_location) {
          new maps.Marker({
            position: value.last_location,
            map,
            icon: imageCar,
            title: "Conductor!",
          });
        }

        console.log("Connected user");

        console.log(conductors);

        this.setState({
          conductors,
        });
      }
    });
  }

  createTransaction() {
    if (this.state.phoneNumber) {
      let paymentCharge = {
        type_of_payment: 23,
        quantity_conductor: this.state.price - this.state.price * 0.15,
        quantity_total: this.state.price,
        quantity_company: this.state.price * 0.15,
        discount: 0,
        price: {
          isGoingBetweenStates: false,
          ...this.state.settings,
        },
      };

      let transaction = {
        origin: {
          coordinates: {
            latitude: this.state.origin_location_client.lat,
            longitude: this.state.origin_location_client.lng,
          },
          detail: "",
        },
        destination: {
          coordinates: {
            latitude: this.state.destination_location.lat,
            longitude: this.state.destination_location.lng,
          },
          detail: "",
        },
        km_estimate: "2km",
        time_distance_aprox: "",
        id: `ID-${Math.round(Math.random() * 100)}`,
        client: {
          phoneNumber: `+52${this.state.phoneNumber}`,
          first_name: `${this.state.name}`,
          last_name: ``,
        },
        direction_destination: this.state.direction_destination,
        direction_location_client: this.state.direction_location_client,
        payment: paymentCharge,
        one_conductor: this.state.currentConductor,
      };

      console.log(transaction)

      this.notifyCreationToServer(transaction);
    } else {
      alert("Ingreso el numero");
    }
  }

  notifyCreationToServer = (transaction) => {
    Axios.post(`${BASE_URL}/conductor/request_drive_alternative`, transaction)
      .then((response) => {
        console.log(response.data.client);
        if (response.status == 200) {
          this.setState({
            markerOrigin: null,
            markerDestination: null,
            isMarkerOrigin: false,
            isMarkerDestination: false,
            conductors: [],
            clients: [],
            directionOrigin: "",
            directionDestination: "",
            km: 0,
            minutes: 0,
            price: 0,
            phoneNumber: "",
            name: "",
            origin_location_client: null,
            destination_location: null,
            conductor: null,
            client: null,
            transaction: transaction,
            status: "pending",
            listenToRide: true,
          });

          Modal.success({
            content: "Viaje creado con exito",
          });

          console.log(response.data.client);

          let ref = firebaseDatabase
            .ref("users")
            .child(`${response.data.client._id}`)
            .child("rides")
            .child(`${transaction.id}`);

          ref.on("value", (snapshot) => {
            if (snapshot.exists()) {
              let data = snapshot.val();

              console.log(data);

              if (data.status == "CONDUCTOR_SEARCHING") {
                this.setState({
                  conductor_chossen: data.conductor_chossen,
                });
              }

              if (data.status == "accepted") {
                let conductor = data.conductor_chossen;
                this.setState({ conductor_chossen: conductor });

                ref.remove();
                ref.off("value");

                console.log(conductor.id);

                firebaseDatabase
                  .ref("online")
                  .child(`${conductor.id}`)
                  .child("transaction")
                  .on("value", (snapshot) => {
                    if (snapshot.exists()) {
                      this.setState({ status: snapshot.val().status });

                      if (snapshot.val().status == "conductor_finished_ride") {
                        setTimeout(() => {
                          this.setState({
                            transaction: null,
                          });
                        }, 3000);
                      }
                    }
                  });
              }

              this.setState({ status: data.status });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkIfTwoLocationsAreLoaded() {
    const {
      origin_location_client,
      destination_location,
      markerOrigin,
      markerDestination,
      directionsDisplay,
    } = this.state;

    console.log(origin_location_client, destination_location);

    if (origin_location_client != null && destination_location != null) {
      let maps = this.state.maps;
      let map = this.state.map;

      if (maps) {
        const bounds = new maps.LatLngBounds();

        if (this.state.mapLoaded) {
          if (markerOrigin != null) {
            markerOrigin.setMap(null);
          }

          if (markerDestination) {
            markerDestination.setMap(null);
          }

          let newMarkerOrigin = new maps.Marker({
            position: { ...origin_location_client },
            map,
            title: "Origen",
          });

          let newMarkerDestination = new maps.Marker({
            position: { ...destination_location },
            map,
            title: "Destino",
          });

          bounds.extend(
            new maps.LatLng(
              origin_location_client.lat,
              origin_location_client.lng
            )
          );

          bounds.extend(
            new maps.LatLng(destination_location.lat, destination_location.lng)
          );

          map.fitBounds(bounds);

          var start = new maps.LatLng(
            origin_location_client.lat,
            origin_location_client.lng
          );

          var end = new maps.LatLng(
            destination_location.lat,
            destination_location.lng
          );

          let directionsDisplay = new maps.DirectionsRenderer({
            suppressMarkers: true,
          });

          if (this.state.directionsDisplay) {
            this.state.directionsDisplay.setMap(null);
          }

          this.setState({
            directionsDisplay,
          });

          var request = {
            origin: start,
            destination: end,
            travelMode: maps.TravelMode.DRIVING,
          };

          this.setState({
            markerOrigin: newMarkerOrigin,
            markerDestination: newMarkerDestination,
          });

          var directionsService = new maps.DirectionsService();
          directionsService.route(request, (response, status) => {
            if (response.routes.length) {
              if (response.routes[0].legs) {
                let legs = response.routes[0].legs;
                const { settings } = this.state;
                if (legs.length) {
                  let leg = response.routes[0].legs[0];
                  let km = leg.distance.value / 1000;
                  let minutes = leg.duration.value / 60;

                  let price = 0.0;

                  if (km <= settings.base_km_condition_min) {
                    price = settings.base_price;
                  } else if (km >= settings.base_km_condition_max) {
                    price = settings.price_large_km * km;
                  } else if (km <= settings.base_km_condition_max) {
                    price = settings.price_short_km * km;
                  }

                  price = Math.round((price + Number.EPSILON) * 100) / 100;
                  minutes = Math.round((minutes + Number.EPSILON) * 100) / 100;

                  this.setState({
                    km,
                    minutes,
                    price,
                  });
                }
              }
            }

            if (status == maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
              this.state.directionsDisplay.setMap(this.state.map);
            }
          });
        }
      }
    }
  }

  render() {
    const { status } = this.state;

    let statusToShow = "";

    console.log(`STATUS ${status}`);

    if (status == "pending") {
      statusToShow = "Buscando conductores...";
    } else if (status == "CONDUCTOR_SEARCHING") {
      statusToShow = `Mandando solicitud a ${
        this.state.conductor_chossen.first_name
          ? this.state.conductor_chossen.first_name
          : this.state.conductor_chossen.firstName
      }`;
    } else if (status == "declined") {
      statusToShow = "No hay conductores cercanos disponibles.";
    } else if (status == "accepted") {
      statusToShow = `Viaje aceptado por ${
        this.state.conductor_chossen.first_name
          ? this.state.conductor_chossen.first_name
          : this.state.conductor_chossen.firstName
      }`;
    } else if (status == "conductor_arrived") {
      statusToShow = `${
        this.state.conductor_chossen.first_name
          ? this.state.conductor_chossen.first_name
          : this.state.conductor_chossen.firstName
      } ha llegado al destino del cliente`;
    } else if (status == "conductor_driving_destination") {
      statusToShow = `${
        this.state.conductor_chossen.first_name
          ? this.state.conductor_chossen.first_name
          : this.state.conductor_chossen.firstName
      } esta manejando al destino`;
    } else if (status == "conductor_finished_ride") {
      statusToShow = "El conductor ha terminado el viaje";
    }

    return (
      <div className="h-100 w-100">
        <Row className="w-100 h-100">
          <Col lg={6} style={{ marginTop: "1.2em", width: "100%" }}>
            <Row style={{ width: "100%" }}>
              {this.state.transaction ? (
                <>
                  <Col md={12} style={{ margin: "3em" }}>
                    <h1>Status del viaje</h1>
                    <p>{statusToShow}</p>
                    {status == "declined" ? (
                      <>
                        <Button
                          type="danger"
                          onClick={() => {
                            this.notifyCreationToServer(this.state.transaction);
                          }}
                        >
                          Reenviar Solicitud
                        </Button>
                        <Button
                          onClick={() => {
                            this.setState({ transaction: null });
                          }}
                        >
                          Generar otro viaje
                        </Button>
                      </>
                    ) : null}
                  </Col>
                </>
              ) : (
                <Col md={12} style={{ marginTop: "3em" }}>
                  <form
                    style={{ margin: "1em", width: "100%", padding: "2em" }}
                  >
                    <Col md={12} style={{ marginTop: "1em" }}>
                      <h1>Dirección de origen</h1>
                      {this.state.direction_location_client.length > 8 ? (
                        <p>{this.state.direction_location_client}</p>
                      ) : null}

                      <Autocomplete
                        style={{ width: "100%" }}
                        className="ant-input"
                        value={this.state.direction_location_client}
                        placeholder="Busca tu dirección"
                        ref={(direction) => (this.direction = direction)}
                        onChange={(e) => {
                          this.setState({
                            direction_location_client: e.target.value,
                          });
                        }}
                        onPlaceSelected={(place) => {
                          console.log(place);

                          if (place.geometry) {
                            this.setState({
                              origin_location_client: {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                              },
                            });

                            this.setState({
                              direction_location_client:
                                place.formatted_address,
                            });

                            this.checkIfTwoLocationsAreLoaded();
                          }
                        }}
                        types={["address"]}
                        componentRestrictions={{ country: "mx" }}
                      />

                      <Checkbox
                        checked={this.state.isMarkerOrigin}
                        style={{ margin: "0.5em" }}
                        onChange={(e) => {
                          this.setState({
                            isMarkerOrigin: e.target.checked,
                            isMarkerDestination: false,
                          });
                        }}
                      >
                        Utilizar mapa
                      </Checkbox>
                    </Col>

                    <Col md={12} style={{ marginTop: "3em", width: "100%" }}>
                      <h1>Conductores Activos</h1>
                      <Select
                        style={{ width: "100%" }}
                        onSelect={(value) => {
                          console.log(value);
                          this.setState({
                            currentConductor: this.state.conductors[value],
                          });
                        }}
                      >
                        {this.state.conductors.map((item, position) => {
                          var name = `${
                            item.firstName ? item.firstName : item.first_name
                          } ${item.lastName ? item.lastName : item.last_name}`;
                          return <Option value={position}>{name}</Option>;
                        })}
                      </Select>
                    </Col>

                    <Col md={12} style={{ marginTop: "3em" }}>
                      <h1>Direccion de destino</h1>

                      {this.state.directionDestination.length > 8 ? (
                        <p>{this.state.directionDestination}</p>
                      ) : null}

                      <Autocomplete
                        style={{ width: "100%" }}
                        className="ant-input"
                        value={this.state.directionDestination}
                        placeholder="Busca tu dirección"
                        onChange={(e) => {
                          this.setState({
                            directionDestination: e.target.value,
                          });
                        }}
                        ref={(direction) => (this.direction = direction)}
                        onPlaceSelected={(place) => {
                          console.log(place);

                          if (place.geometry) {
                            this.setState({
                              destination_location: {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                              },
                            });

                            this.setState({
                              directionDestination: place.formatted_address,
                            });

                            this.checkIfTwoLocationsAreLoaded();
                          }
                        }}
                        types={["address"]}
                        componentRestrictions={{ country: "mx" }}
                      />
                      <Checkbox
                        checked={this.state.isMarkerDestination}
                        style={{ margin: "0.5em" }}
                        onChange={(e) => {
                          this.setState({
                            isMarkerDestination: e.target.checked,
                            isMarkerOrigin: false,
                          });
                        }}
                      >
                        Utilizar mapa
                      </Checkbox>
                    </Col>

                    <Col md={12} style={{ marginTop: "3em" }}>
                      <h1>Cliente</h1>

                      <EditText
                        placeholder="Nombre del cliente"
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value,
                          });
                        }}
                      />

                      <EditText
                        placeholder="Numero del cliente"
                        // type="te"
                        addonBefore="+52"
                        value={this.state.phoneNumber}
                        style={{
                          marginTop: "2em",
                        }}
                        onChange={(e) => {
                          this.setState({
                            phoneNumber: e.target.value,
                          });
                        }}
                      />
                    </Col>

                    {this.state.price > 0 ? (
                      <Col md={12} style={{ marginTop: "3em" }}>
                        <h1>Datos del viaje</h1>
                        <p>Km : {this.state.km}</p>
                        <p>Precio : ${this.state.price}</p>
                        <p>
                          Tiempo estimado de viaje {this.state.minutes} minutos
                        </p>
                      </Col>
                    ) : (
                      ""
                    )}

                    <Button
                      onClick={() => {
                        this.createTransaction();
                      }}
                      style={{
                        width: "98%",
                        marginLeft: "0.5em",
                        marginRight: "0.5em",
                        marginTop: "3em",
                      }}
                      type="primary"
                    >
                      {this.state.currentConductor
                        ? "Enviar viaje a conductor"
                        : "Crear Viaje"}
                    </Button>
                  </form>
                </Col>
              )}
            </Row>
          </Col>

          <Col lg={6} style={{ height: "95%", width: "100%" }}>
            <GoogleMap
              bootstrapURLKeys={{ key: API_GOOGLE_KEY }}
              center={this.state.centerX}
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState({ map: map, maps: maps, mapLoaded: true });

                this.state.maps.event.addListener(map, "click", (event) => {
                  const { isMarkerOrigin, isMarkerDestination } = this.state;

                  let coordinates = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                  };

                  getDirectionsFromMaps(
                    event.latLng.lat(),
                    event.latLng.lng(),
                    (direction) => {
                      if (isMarkerOrigin) {
                        this.setState({
                          direction_location_client: direction,
                          origin_location_client: {
                            ...coordinates,
                          },
                        });
                      } else {
                        this.setState({
                          directionDestination: direction,
                          destination_location: {
                            ...coordinates,
                          },
                        });
                      }

                      this.checkIfTwoLocationsAreLoaded();
                    }
                  );

                  console.log(this.state);
                });
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={16}
            />

            {this.state.mapLoaded && (
              <Polyline
                map={this.state.map}
                maps={this.state.maps}
                locations={this.state.locations}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

class Polyline extends Component {
  componentWillUpdate() {
    this.line.setMap(null);
  }

  componentWillUnmount() {
    this.line.setMap(null);
  }

  getPaths() {
    return this.props.locations;
  }

  render() {
    if (this.props.maps) {
      const Polyline = this.props.maps.Polyline;
      const paths = { path: this.getPaths() };
      this.line = new Polyline(Object.assign({}, () => {}, paths));
      this.line.setMap(this.props.map);
      return null;
    }
  }
}

class Normal extends Polyline {
  renderPolyline() {
    return {
      geodesic: true,
      strokeColor: "#435",
      strokeOpacity: 1,
      strokeWeight: 4,
    };
  }
}

export default CreateRide;
