import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { firebaseDatabase } from '../../helpers/FirebaseHelper'
import { Table, Divider, Tag, Avatar } from 'antd';
import { NavLink } from "react-router-dom"

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: text => <NavLink to={`/dashboard/location-history/${text}`}> Consultar</NavLink>
    },
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status'
    },
];


export class Emergencies extends Component {

    constructor() {
        super()
        this.state = {
            data: [],
        }
    }


    componentDidMount() {
        firebaseDatabase.ref("security").once('value').then(snapshot => {

            const { data } = this.state


            snapshot.forEach(child => {

                let notification = child.val()

                let status = ""

                if (notification.hasSeen) {
                    status = "Consultado"
                } else {
                    status = "Sin consultar"
                }


                data.push({ ...child, status, id: notification.id_transction })

            })

            this.setState({
                data
            })

        })
    }


    render() {
        return (
            <Grid fluid style={{ overflowY: "auto" }}>
                <Row style={{ margin: "2em" }}>
                    <Col lg={12}>
                        <h1>Emergencias</h1>
                        <Col md={12}>
                            <Table style={{ marginTop: "2em" }} columns={columns} dataSource={this.state.data} />
                        </Col>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default Emergencies
