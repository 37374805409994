import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Radio } from 'antd';
import { Input, Button, message } from 'antd';
import { BASE_URL } from '../../data/constants'
import Axios from 'axios';


const { TextArea } = Input;



export class Alert extends Component {

    constructor() {
        super()
        this.state = {
            isClient: true,
            title: "",
            description: "",
        }


        this.sendNotification = this.sendNotification.bind(this)
    }


    sendNotification() {

        const { title, description, isClient } = this.state

        console.log(title, description, isClient)

        let url = "general_notification_conductor"

        if (isClient) {

            url = "general_notification_clients"
        }


        Axios.post(`${BASE_URL}/notifications/${url}`, { title, description }).then(response => {

            if (response.status == 200) {
                message.success('Notificacion enviada');
            } else {
                message.error('Error intente de nuevo');
            }
        }).catch(error => {
            message.error('Error intente de nuevo');
        })


    }


    render() {
        return (
            <Grid fluid style={{ margin: "3em" }}>
                <Row>
                    <Col md={12}>
                        <h1>Alertas</h1>
                    </Col>
                    <Col md={12}>
                        <div>
                            <Radio.Group onChange={(value) => {
                                console.log(value.target.value);
                                this.setState({
                                    isClient: value.target.value == 1
                                })
                            }} defaultValue={1}>
                                <Radio value={1}>Clientes</Radio>
                                <Radio value={2}>Conductores</Radio>
                            </Radio.Group>
                        </div>
                    </Col>
                    <Col md={6} style={{ marginTop: "2em" }}>
                        <label>Titulo</label>
                        <Input style={{ marginTop: "0.5em" }} size="large" onChange={(value) => {
                            this.setState({
                                title: value.target.value
                            })
                        }} />

                    </Col>
                    <Col md={6} style={{ marginTop: "2em", marginRight: "1em" }}>
                        <label>Descripción</label>
                        <TextArea style={{ marginTop: "0.5em" }} size="large"
                            onChange={(value) => {
                                this.setState({
                                    description: value.target.value
                                })
                            }}
                        />

                    </Col>

                    <Col md={12} style={{ marginTop: "2em" }}>
                        <Button type="primary" onClick={() => {
                            this.sendNotification()
                        }}>Mandar Notificación</Button>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default Alert
