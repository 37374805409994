import React, { Component } from "react";
import { Button, message } from "antd";
import Axios from "axios";
import { BASE_URL } from "../../data/constants";

export class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: ``,
      confirmPassword: ``,
      _id: ``,
      type: ``,
      loadingButton: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      _id: this.props.match.params.id,
    });
  }

  handleSubmit() {
    const { password, confirmPassword } = this.state;

    if (password) {
      if (confirmPassword) {
        if (password == confirmPassword) {
          this.setState({
            loadingButton: true,
          });
          Axios.put(`${BASE_URL}/client/password`, {
            password: password,
            _id: this.state._id,
            type: this.state.type,
          })
            .then((response) => {
              message.success(`Contraseña Actualizado con exito`);

              this.setState({
                loadingButton: false,
              });

              setTimeout(() => {
                window.location.href = "http://geocab.com.mx/";
              }, 1500);
            })
            .catch((error) => {});
        } else {
          alert("Las contraseñas deben de coincidir");
        }
      } else {
        alert("Ingrese la confirmación de contraseña");
      }
    } else {
      alert("Ingrese su contraseña");
    }
  }

  render() {
    return (
      <div id="box">
        <form id="myform-search" method="post" autocomplete="off" />
        <h1>
          Cambio de contraseña <span>Ingrese una contraseña</span>
        </h1>
        <form>
          <p>
            <input
              type="password"
              placeholder="Enter Password"
              onChange={(e) => {
                this.setState({
                  password: e.target.value,
                });
              }}
              id="p"
              class="password"
            />
            <button class="unmask" type="button"></button>
          </p>
          <p>
            <input
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => {
                this.setState({
                  confirmPassword: e.target.value,
                });
              }}
              id="p-c"
              class="password"
            />
            <button class="unmask" type="button"></button>
            <div id="strong">
              <span></span>
            </div>
            <div id="valid"></div>
            <small>
              Must be 6+ characters long and contain at least 1 upper case
              letter, 1 number, 1 special character
            </small>
          </p>

          <Button
            loading={this.state.loadingButton}
            type="primary"
            block
            onClick={this.handleSubmit}
          >
            Reiniciar contraseña
          </Button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
