import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Avatar, Card, Icon, List, Tag, Button, Modal, message } from 'antd';
import Axios from 'axios';
import { BASE_URL } from '../../data/constants';
import Tripitem from '../../components/TripItem'
const { Meta } = Card


const { confirm } = Modal;



const DocumentCard = (props) => {

    return (
        <Col md={12} style={{ justifyContent: 'center', marginTop: '10px' }}>
            <Card
                style={{ width: "100%" }}
                cover={
                    <img
                        alt="example"
                        className="card-img-top image-custom-normal center-cropped"
                        src={props.document.url}
                    />
                }
            >
                <Meta
                    title={props.document.name}
                />
            </Card>
        </Col>
    )
}

export class ClientProfile extends Component {

    constructor() {
        super()
        this.state = {
            profile_url: '',
            first_name: '',
            last_name: '',
            phoneNumber: '',
            documents: [],
            rides: [],
            loading: true,
            isVerifyButtonDisabled: false,
            balance: 0,
            status_verification: '',
        }
        this.sendToVerification = this.sendToVerification.bind(this)
    }

    sendToVerification() {
        let id = this.props.match.params.id

        Axios.post(`${BASE_URL}/super_user/re_activate`, {
            id: id
        }).then(response => {
            if (response.status == 200) {
                message.success('Notificacion enviada');
            } else {
                message.error('Error intente de nuevo');
                this.setState({
                    isVerifyButtonDisabled: false
                })
            }
        }).catch(error => {
            message.error('Error intente de nuevo');
            this.setState({
                isVerifyButtonDisabled: false
            })
        })
    }

    componentDidMount() {
        let id = this.props.match.params.id

        console.log(`The ID is ${id}`);

        Axios.get(`${BASE_URL}/client/search/${id}`).then(response => {

            console.log({ ...response.data.client });

            let data = response.data.client

            console.log(data.status_verification);


            if (data.status_verification == "Successful") {
                this.setState({
                    isVerifyButtonDisabled: false
                })
            } else {
                this.setState({
                    isVerifyButtonDisabled: true
                })
            }

            this.setState({
                ...response.data.client,
                loading: false
            })
        }).catch(error => {
            console.log(error);
        })


        Axios.get(`${BASE_URL}/client/transaction/${id}`).then(response => {

            console.log(response.data.result);
            this.setState({
                rides: response.data.result,
            })


        }).catch(error => {
            console.log(error);
        })

    }

    render() {

        return this.state.loading === true ? (
            <div style={{ margin: "2em" }}>
                <h1>Cargando</h1>
            </div>
        ) : (
                <Grid fluid style={{ overflowY: "auto" }}>
                    <Row style={{ margin: "2em" }}>
                        <Col>
                            <Avatar size="large" icon="user" size={120} src={this.state.profile_url} />
                        </Col>
                        <Col md={3} style={{
                            marginLeft: "1em",
                            marginTop: "1em"
                        }}>
                            <h2
                                style={{ marginBottom: "5px" }}
                            >{this.state.first_name} {this.state.last_name}</h2>
                            <h3>{this.state.phoneNumber}</h3>
                            <h3>Saldo: ${Math.round(this.state.balance * 100) / 100}</h3>
                            <Button type="danger" onClick={() => {
                                confirm({
                                    title: '¿Estas seguro de volver a verificar este usuario?',
                                    content: 'Una vez realizada esta acción el usuario no podrá entrar a la applicación',
                                    onOk: () => {


                                        this.sendToVerification()

                                        this.setState({
                                            isVerifyButtonDisabled: true
                                        })
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                });

                            }}

                                disabled={this.state.isVerifyButtonDisabled}

                            >Re-Verificar</Button>
                        </Col>


                    </Row>
                    <Row style={{ margin: "2em" }}>
                        <Col lg={3}>
                            <h1>Documentos</h1>
                            {
                                this.state.documents.map(document => {
                                    return (
                                        <DocumentCard document={document} />
                                    )

                                })
                            }

                        </Col>
                        <Col lg={9}>
                            <h1>Viajes</h1>
                            <Tripitem data={this.state.rides} />
                        </Col>
                    </Row>
                </Grid>
            );
    }
}

export default ClientProfile
