import React, { Component } from "react";
import GoogleMap from "google-map-react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Table, Divider, Tag, Avatar } from "antd";
import { fitBounds } from "google-map-react/utils";
import { BASE_URL } from "../../data/constants";
import Axios from "axios";
import moment from "moment";
import "moment/locale/es";
const API_GOOGLE_KEY = "AIzaSyDDO9EbDTQCSSfn2f00MjajI7gV3ied6xI";

const columns = [
  {
    title: "Status",
    dataIndex: "message",
    key: "message",
  },
  {
    title: "Horario",
    dataIndex: "date",
    key: "date",
  },
];

export class HistoryLocation extends Component {
  constructor() {
    super();
    this.state = {
      centerX: [19.418929, -99.107568],
      map: null,
      maps: null,
      mapLoaded: false,
      locations: [{}],
      status: [],
      data: {
        client: {},
        conductor: {},
        car: {},
        payment: {},
        origin_location_client: {},
        destination_location: {},
      },
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    Axios.get(`${BASE_URL}/super_user/transaction_ride/${id}`).then(
      (response) => {
        if (response.status == 200) {
          console.log(response.data);

          let data = response.data;

          console.log(data);

          let origin_location_client = data.origin_location_client.coordinates;

          let destination_location = data.destination_location.coordinates;

          let locationsConductor = data.locations_conductor;

          //   let formattedDirections = locationsConductor.map(location => {
          //     return {
          //       lat: location.location.coordinates[0],
          //       lng: location.location.coordinates[1]
          //     };
          //   });

          //   this.setState({
          //     locations: formattedDirections
          //   });

          //const { maps, map } = this.state.maps

          let maps = this.state.maps;
          let map = this.state.map;

          if (maps) {
            const bounds = new maps.LatLngBounds();

            if (this.state.mapLoaded) {
              new maps.Marker({
                position: {
                  lat: origin_location_client[0],
                  lng: origin_location_client[1],
                },
                map,
                title: "Origen",
              });

              new maps.Marker({
                position: {
                  lat: destination_location[0],
                  lng: destination_location[1],
                },
                map,
                title: "Destino",
              });

              bounds.extend(
                new maps.LatLng(
                  origin_location_client[0],
                  origin_location_client[1]
                )
              );

              bounds.extend(
                new maps.LatLng(
                  destination_location[0],
                  destination_location[1]
                )
              );

              map.fitBounds(bounds);
            }
          }

          this.setState({
            data: { ...data },
            status: response.data.track,
            // centerX: [origin_location_client[0], destination_location[1]]
          });
        }
      }
    );
  }

  render() {
    let type_of_payment = "";

    let status_for_trip = "";

    if (this.state.data.payment) {
      if (this.state.data.payment.type_of_payment) {
        switch (this.state.data.payment.type_of_payment) {
          case 23: {
            type_of_payment = "Efectivo";
            break;
          }
        }
      }
    }

    if (!type_of_payment) {
      type_of_payment = "Pago con Aliz";
    }

    return (
      <div className="h-100">
        <Row className="w-100 h-100">
          <Col lg={6} style={{ marginLeft: "0.2em", height: "90%" }}>
            <GoogleMap
              bootstrapURLKeys={{ key: API_GOOGLE_KEY }}
              center={this.state.centerX}
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState({ map: map, maps: maps, mapLoaded: true });
                this.state.maps.event.addListener(map, "click", (event) => {});
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={16}
            />
            {this.state.mapLoaded && (
              <Polyline
                map={this.state.map}
                maps={this.state.maps}
                locations={this.state.locations}
              />
            )}
          </Col>
          <Col
            lg={5}
            style={{ marginTop: "1.2em", width: "100%", marginLeft: "3.5em" }}
          >
            <Row style={{ width: "100%" }}>
              {/* <Avatar className={classes.purpleAvatar}>OP</Avatar> */}

              <Col md={12}>
                <h3 className={"name"}>
                  Viaje de{" "}
                  {
                    <a>
                      {moment(this.state.data.date).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                    </a>
                  }
                </h3>
              </Col>

              <h2 style={{ marginLeft: "0.5em" }}>Información del viaje</h2>

              <Col md={12} style={{ marginTop: "1em" }}>
                <Row>
                  <Col md={6}>
                    <div style={{ display: "flex" }}>
                      <Avatar
                        size={40}
                        icon="user"
                        style={{ marginTop: "0.2em" }}
                      />

                      <div style={{ marginLeft: "0.5em" }}>
                        <Tag color={"orange"}>Cliente</Tag>
                        <h3 style={{ marginTop: "0.3em" }}>
                          {this.state.data.client ? (
                            <>
                              {this.state.data.client.first_name}{" "}
                              {this.state.data.client.last_name}
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ marginTop: "1.5em" }}>
                      {this.state.data.payment ? (
                        <h3>
                          $
                          {Math.round(
                            this.state.data.payment.quantity_total * 100
                          ) / 100}{" "}
                          - {type_of_payment}
                          <br/>
                          {this.state.data.payment.discount > 0 ? (
                            <Tag style={{ marginTop: "1em" }} color="#f50">
                              Aplico un cupon de descuento{" "}
                              {this.state.data.payment.discount} %
                            </Tag>
                          ) : null}
                        </h3>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ marginTop: "1.5em" }}>
                <Row>
                  <Col md={6}>
                    <div style={{ display: "flex" }}>
                      <Avatar
                        size={40}
                        icon="user"
                        style={{ marginTop: "0.5em" }}
                      />
                      <div style={{ marginLeft: "0.5em" }}>
                        <Tag color={"blue"}>Conductor</Tag>
                        <h3 style={{ marginTop: "0.35em" }}>
                          {this.state.data.conductor.first_name}{" "}
                          {this.state.data.conductor.last_name}
                        </h3>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ marginTop: "1.8em" }}>
                      <h3>
                        {this.state.data.car.brand}{" "}
                        {this.state.data.car.model_name} -{" "}
                        {this.state.data.car.number_plate}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <Table
                  style={{ marginTop: "2em" }}
                  columns={columns}
                  dataSource={this.state.status}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

class Polyline extends Component {
  componentWillUpdate() {
    this.line.setMap(null);
  }

  componentWillUnmount() {
    this.line.setMap(null);
  }

  getPaths() {
    return this.props.locations;
  }

  render() {
    if (this.props.maps) {
      const Polyline = this.props.maps.Polyline;
      const paths = { path: this.getPaths() };
      this.line = new Polyline(Object.assign({}, () => {}, paths));
      this.line.setMap(this.props.map);
      return null;
    }
  }
}

class Normal extends Polyline {
  renderPolyline() {
    return {
      geodesic: true,
      strokeColor: "#435",
      strokeOpacity: 1,
      strokeWeight: 4,
    };
  }
}

export default HistoryLocation;
