import React, { Component } from "react";
import axios from "axios";
import { BASE_URL, API_GOOGLE_KEY } from "../../data/constants";
import QRCode from "react-qr-code";

export class ShareCompany extends Component {
  constructor() {
    super();
    this.state = {
      storeInfo: {},
    };

    this.findStoreById = this.findStoreById.bind(this);
  }

  findStoreById(id) {
    if (id) {
      axios.get(`${BASE_URL}/super_user/store/${id}`).then((response) => {
        console.log(response.data);

        if (response.status == 200) {
          this.setState({
            storeInfo: { ...response.data },
          });
        }
      });
    }
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.findStoreById(id);
  }

  render() {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <h1>{this.state.storeInfo.name}</h1>
          <h2>{this.state.storeInfo.slug}</h2>
        </div>

        <div className="qr">
          {this.state.storeInfo._id ? (
            <QRCode
              value={
                this.state.storeInfo._id
              }
              size={150}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ShareCompany;
