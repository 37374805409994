import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Table, Divider, Tag, Input, message } from "antd";
import { NavLink } from "react-router-dom";
import { labeledStatement } from "@babel/types";
import { BASE_URL } from "../../data/constants";
import Axios from "axios";
import { Pagination } from "antd";
import Item from "antd/lib/list/Item";

export class Users extends Component {
  constructor() {
    super();

    this.state = {
      users: [],
      pagesLoaded: [],
      originalUsers: [],
      loading: true,
      typeOfuser: "",
      current: 1,
      total: 1,
    };

    this.fetchClients = this.fetchClients.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchClients() {
    Axios.get(`${BASE_URL}/client/`)
      .then((response) => {
        let array = response.data.result.filter((user) => {
          return user.first_name;
        });

        let secondFilter = array.map((user, index) => {
          let counter = index + 1;
          return {
            ...user,
            type: "client",
            counter,
          };
        });

        this.setState({
          originalUsers: secondFilter,
          users: secondFilter,
          loading: false,
        });
      })
      .catch((error) => {});
  }

  fetchData(typeOfUser) {
    let url_users = "";
    let total_users = "";

    if (typeOfUser == "clients") {
      this.fetchClients();
    } else {
      url_users = `${BASE_URL}/conductor/paging/conductors`;

      Axios.post(url_users)
        .then((response) => {
          console.log(response.data);

          let array = response.data.filter((user) => {
            return user.first_name;
          });

          array = array.map((item, index) => {
            let counter = index + 1;
            return {
              ...item,
              counter,
              type: "conductor",
            };
          });

          this.setState({
            users: array,
            originalUsers: array,
            loading: false,
          });
        })
        .catch((error) => {});
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    let type = this.props.match.params.type;
    this.setState({
      loading: true,
    });

    this.fetchData(type);

    this.setState({
      typeOfuser: type == "clients" ? "Clientes" : "Conductores",
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getUsers();
    }
  }

  render() {
    console.log(this.state.typeOfuser);
    return (
      <Grid style={{ marginTop: "1em" }}>
        <h1>Usuarios - {this.state.typeOfuser}</h1>

        <Input
          style={{ marginBottom: "10px" }}
          onChange={(e) => {
            let value = e.target.value;

            console.log(value);
            let filterArray = this.state.originalUsers.filter((item) => {
              let fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
              console.log(fullName);
              return fullName.includes(value.toLowerCase());
            });

            console.log(filterArray);

            this.setState({ users: filterArray });
          }}
          placeholder="Busca tu usuario"
        />

        <Table
          size={`small`}
          columns={[
            {
              title: "Num.",
              dataIndex: "counter",
              key: "counter",
            },
            {
              title: "Nombre",
              key: "name",
              render: (text, fullObject, y) => {
                return (
                  <NavLink
                    to={
                      fullObject.type == "client"
                        ? `/dashboard/client/${fullObject._id}`
                        : `/dashboard/conductor/${fullObject._id}`
                    }
                  >
                    {fullObject.first_name} {fullObject.last_name}
                  </NavLink>
                );
              },
            },
            {
              title: "Correo Electronico",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Numero Telefonico",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
            },
            {
              title: "Acciones:",
              dataIndex: "_id",
              key: "_id",
              render: (text, record) => {
                return (
                  <a
                    onClick={() => {
                      if (record.type == "conductor") {
                        Axios.delete(
                          `${BASE_URL}/super_user/conductor/${text}`
                        ).then((response) => {
                          if (response.status == 200) {
                            const { users } = this.state;

                            let filter = users.filter(
                              (item) => item._id != text
                            );

                            this.setState({ users: filter });

                            message.success("Conductor eliminado");
                          }
                        });
                      } else {
                        Axios.delete(
                          `${BASE_URL}/super_user/client/${text}`
                        ).then((response) => {
                          if (response.status == 200) {
                            const { users } = this.state;

                            let filter = users.filter(
                              (item) => item._id != text
                            );

                            this.setState({ users: filter });

                            message.success("Cliente eliminado");
                          }
                        });
                      }
                    }}
                  >
                    Eliminar Usuario
                  </a>
                );
              },
            },
          ]}
          dataSource={this.state.users}
          loading={this.state.loading}
          pagination={true}
        />
      </Grid>
    );
  }
}

export default Users;
