import React, { Component } from "react";
import { API_GOOGLE_KEY, BASE_URL } from "../../data/constants";
import {
  Tabs,
  Icon,
  Avatar,
  Drawer,
  Dropdown,
  Menu,
  Radio,
  List,
  Typography,
  message,
  Steps,
  Badge,
} from "antd";
import { Modal, Button, Tag } from "antd";

import { Grid, Row, Col } from "react-flexbox-grid";
import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Input,
} from "react-google-maps";
import { firebaseDatabase } from "../../helpers/FirebaseHelper";

import Drivers from "../../img/drivers.png";
import RedMarker from "../../img/markers/redMarker.png";
import OrangeMarker from "../../img/markers/orangeMarker.png";
import BlueLightMarker from "../../img/markers/blugLightMarker.png";
import BlueDarkMarker from "../../img/markers/blugDarkMarker.png";
import YellowMarker from "../../img/markers/yellowMarker.png";
import GreenMarker from "../../img/markers/greenMarker.png";
import phoneImagePlaceHolder from "../../img/phone_placeholder.png";
import axios from "axios";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Step } = Steps;

var numDeltas = 100;
var delay = 10; //milliseconds

const Map = withScriptjs(
  withGoogleMap((props) => {
    let iconMarker;

    let onlineMarker = new window.google.maps.MarkerImage(
      GreenMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    let pickingClientMarker = new window.google.maps.MarkerImage(
      BlueLightMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    let waitingClientMarker = new window.google.maps.MarkerImage(
      BlueDarkMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    let drivingDestinationMarker = new window.google.maps.MarkerImage(
      YellowMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    let driverFinishRide = new window.google.maps.MarkerImage(
      OrangeMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    let cancelledRideMarker = new window.google.maps.MarkerImage(
      RedMarker,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60)
    );

    const bounds = new window.google.maps.LatLngBounds();

    props.conductors.map((item) => {
      if (item.last_location || item.location) {
        bounds.extend(
          item.last_location
            ? new window.google.maps.LatLng(
                item.last_location.lat,
                item.last_location.lon
              )
            : new window.google.maps.LatLng(
                item.location.latitude,
                item.location.longitude
              )
        );
      }
    });

    const isExistCurrentDriver =
      props.state.currentDriver.last_location !== undefined &&
      props.isShowMoreInfoDriver &&
      props.state.currentDriver !== null;

    return (
      <GoogleMap
        defaultZoom={12}
        zoom={props.zoom}
        ref={(map) => {
          if (map && props.conductors.length > 3 && props.isFirstLoad) {
            props.doneShowingMap();
            map.fitBounds(bounds);
          }

          props.onMapReady(map);
        }}
        center={props.center}
      >
        {props.conductors.map((conductor) => {
          if (conductor.transaction) {
            //console.log(conductor.transaction.status);

            if (conductor.transaction.status != "declined") {
              // iconMarker = busyMarker;
              if (conductor.transaction.status == "accepted") {
                iconMarker = pickingClientMarker;
              } else if (
                conductor.transaction.status == "conductor_driving_destination"
              ) {
                iconMarker = drivingDestinationMarker;
              } else if (conductor.transaction.status == "conductor_arrived") {
                iconMarker = waitingClientMarker;
              } else if (
                conductor.transaction.status == "conductor_finished_ride"
              ) {
                iconMarker = driverFinishRide;
              } else if (
                conductor.transaction.status == "cancelled_client" ||
                conductor.transaction.status == "conductor_cancelled"
              ) {
                iconMarker = cancelledRideMarker;
              } else {
                iconMarker = onlineMarker;
              }
            } else {
              iconMarker = onlineMarker;
            }
          } else {
            iconMarker = onlineMarker;
          }

          return (
            <Marker
              position={
                conductor.last_location
                  ? {
                      lat: conductor.last_location.lat,
                      lng: conductor.last_location.lon,
                    }
                  : {
                      lat: conductor.location.latitude,
                      lng: conductor.location.longitude,
                    }
              }
              icon={iconMarker}
              defaultAnimation={window.google.maps.Animation.DROP}
              onClick={() => {
                props.onMapClick(conductor);
              }}
            />
          );
        })}

        {isExistCurrentDriver && (
          <InfoWindow
            onCloseClick={() => props.onHiddenWindowInfoDriver()}
            position={{
              lat: props.state.currentDriver.last_location.lat,
              lng: props.state.currentDriver.last_location.lon,
            }}
          >
            <Row style={{ margin: 0 }}>
              <Avatar
                src={props.state.currentDriver.profilePicture}
                style={{ padding: 0 }}
              />
              <p
                style={{ margin: 0, padding: 8 }}
              >{`${props.state.currentDriver.firstName} ${props.state.currentDriver.lastName}`}</p>
            </Row>
          </InfoWindow>
        )}

        {/* {props.isForRender ? (
          <Marker
            position={props.center}
            icon={markerOffline}
            defaultAnimation={window.google.maps.Animation.DROP}
          />
        ) : (
          ""
        )} */}
      </GoogleMap>
    );
  })
);

const formatter = new Intl.NumberFormat("en-MX", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const formatNumber = new Intl.NumberFormat("en-MX", {
  minimumFractionDigits: 2,
});

export class MapView extends Component {
  constructor() {
    super();
    this.state = {
      currentDriver: [],
      zoom: 14,
      currentStep: 0,
      isForRender: false,
      showTestModal: false,
      new_location: null,
      typeSelect: "status",
      lastValue: "active",
      loading: true,
      conductors: [],
      deviceListener: null,
      offline: [],
      visibleVerification: false,
      visible: false,
      center: { lat: 19.487487, lng: -99.130259 },
      map: null,
      isFirstLoad: true,
    };

    this.moveMarker = this.moveMarker.bind(this);
    this.notifyDatasetChanged = this.notifyDatasetChanged.bind(this);
    this.moveToNextLocation = this.moveToNextLocation.bind(this);
    this.getOfflineConductors = this.getOfflineConductors.bind(this);
  }

  state = { visible: false };

  showModal = () => {
    this.setState({
      visibleVerification: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visibleVerification: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visibleVerification: false,
    });
  };

  getOnlineConductors() {
    firebaseDatabase.ref("online").on("child_added", (snapshot) => {
      const { conductors } = this.state;
      console.log("NEW USER ADDED");
      let value = { ...snapshot.val() };
      console.log(value);
      conductors.push({
        ...value,
        key: snapshot.key,
        indexToIterate: 0,
        deltaLat: null,
        deltaLng: null,
        indexAnimation: 0,
        isOffline: false,
      });

      console.log("Connected user");

      this.setState({
        conductors,
      });

      console.log(conductors)
    });

    firebaseDatabase.ref("online").on("child_removed", (snapshot) => {
      let keyToFind = snapshot.key;

      const { conductors } = this.state;

      let newArray = conductors.filter((conductor, y) => {
        return conductor.id != keyToFind ;
      });

      this.setState({
        conductors: newArray,
      });
    });

    firebaseDatabase.ref("online").on("child_changed", (snapshot) => {

      
      let indexToFind = -1;

      let keyToFind = snapshot.key;

      const { conductors } = this.state;

      conductors.filter((conductor, y) => {
        if (conductor.id == keyToFind || conductor.key == keyToFind) {
          indexToFind = y;
        }
      });

      let value = snapshot.val();

      let destinationLocation = [
        value.location.latitude,
        value.location.longitude,
      ];

    

      if (indexToFind >= 0) {
        let conductor = conductors[indexToFind];
        this.moveToNextLocation(destinationLocation, indexToFind);

        conductor = {
          ...conductor,
          ...value,
        };

        if (value.transaction) {
          conductor["transaction"] = value.transaction;
          conductors[indexToFind].transaction = value.transaction;
        } else {
          conductor["transaction"] = null;
          conductors[indexToFind].transaction = null;
        }

        conductor["location"] = destinationLocation;


        conductors[indexToFind] = conductor
        
      


        this.setState({
          conductors: this.state.conductors,
        });

        this.setState({
          conductors,
        });
      }
    });
  }

  notifyDatasetChanged(value, type) {
    this.handleOnChangeRadio(
      {
        target: { value: value },
      },
      type
    );
  }

  getOfflineConductors() {
    axios
      .get(`${BASE_URL}/conductor/offline/conductors`)
      .then((response) => {
        if (response.data) {
          this.setState({
            offline: response.data.map((conductor) => {
              return {
                ...conductor,
                indexToIterate: 0,
                deltaLat: null,
                deltaLng: null,
                indexAnimation: 0,
                isOffline: true,
              };
            }),
            loading: false,
          });
        }
      })
      .catch((error) => {});
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  componentDidMount() {
    this.getOfflineConductors();
    this.getOnlineConductors();

    this.setState({
      currentsAllDrivers: this.state.conductors,
      typeSelect: "active",
    });
    window.addEventListener("keydown", this.handleListenerKeyboard);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleListenerKeyboard);
  }

  moveToNextLocation(location, index) {

    this.transition(location, index, () => {
      let arrayOfDrivers = this.state.conductors;
      arrayOfDrivers[index] = {
        ...arrayOfDrivers[index],
        lat: location[0],
        lng: location[1],
        last_location: { lat: location[0], lon: location[1] },
        indexToIterate: arrayOfDrivers[index].indexToIterate + 1,
      };

      this.setState({ conductors: arrayOfDrivers });
    });
  }

  transition(result, index, cb) {
    if (this.state.conductors[index].last_location) {
      let position = [
        this.state.conductors[index].last_location.lat,
        this.state.conductors[index].last_location.lon,
      ];

      console.log(this.state.conductors[index].last_location)
      console.log(position)

      this.state.conductors[index].indexAnimation = 0;
      this.state.conductors[index].deltaLat =
        (result[0] - position[0]) / numDeltas;
      this.state.conductors[index].deltaLng =
        (result[1] - position[1]) / numDeltas;
      this.moveMarker(cb, index);
    }
  }

  moveMarker(cb, index) {
    if (this.state.conductors[index]) {
      if (this.state.conductors[index].last_location) {
        console.log("Move marker")
        console.log(this.state.conductors[index].last_location)
        let position = [
          this.state.conductors[index].last_location.lat,
          this.state.conductors[index].last_location.lon,
        ];

        console.log(position)
        position[0] += this.state.conductors[index].deltaLat;
        position[1] += this.state.conductors[index].deltaLng;

        console.log(position)
        let arrayOfDrivers = this.state.conductors;
        arrayOfDrivers[index] = {
          ...arrayOfDrivers[index],
          last_location: { lat: position[0], lon: position[1] },
        };

        console.log(arrayOfDrivers)

        this.setState({
          conductors: arrayOfDrivers,
        });
        if (this.state.conductors[index].indexAnimation !== numDeltas) {
          this.state.conductors[index].indexAnimation++;
          setTimeout(() => {
            this.moveMarker(cb, index);
          }, delay);
        } else if (this.state.conductors[index].indexAnimation === numDeltas) {
          cb();
        }
      }
    }
  }

  handleOnChange = (e) => {
    const isDriverActive = this.state.typeSelect === "active";
    const currentsAllDrivers = [];
    this.state[isDriverActive ? "conductors" : "offline"].forEach((item) => {
      if (
        `${item.firstName !== undefined ? item.firstName : item.first_name} ${
          item.lastName !== undefined ? item.lastName : item.last_name
        }`
          .toUpperCase()
          .indexOf(e.target.value.toUpperCase()) === -1
      ) {
        return;
      }

      currentsAllDrivers.push(item);
    });

    this.setState({ currentsAllDrivers });
  };

  handleOnChangeRadio = (e, type) => {
    this.setState({
      lastValue: e.target.value,
      typeSelect: type,
    });

    console.log(e.target.value, type);
    switch (type) {
      case "status":
        switch (e.target.value) {
          case "active":
            this.setState({
              currentsAllDrivers: this.state.conductors,
            });
            break;

          default:
            this.setState({
              currentsAllDrivers: this.state.offline,
            });
            break;
        }
        break;

      case "statusRequest": {
        let value = e.target.value;

        if (value == "all") {
          this.setState({
            currentsAllDrivers: this.state.conductors,
            typeSelect: type,
          });
        } else {
          let filter = this.state.conductors.filter((conductor) => {
            if (conductor.transaction) {
              return conductor.transaction.status == value;
            }
            return false;
          });
          this.setState({
            currentsAllDrivers: filter,
            typeSelect: type,
          });
        }

        break;
      }
      default:
        console.log("Filter with status current");
        break;
    }
  };

  handleOnSelectDriver = (driver) => {
    this.setState({ isVisibleDrawer: true, currentDriver: driver });
  };

  removeData = () => {
    firebaseDatabase
      .ref("online")
      .child(this.state.currentDriver.id)
      .child("new_location")
      .remove();

    firebaseDatabase
      .ref("online")
      .child(this.state.currentDriver.id)
      .child("mobile")
      .remove();

    firebaseDatabase
      .ref("online")
      .child(this.state.currentDriver.id)
      .child("service")
      .remove();

    firebaseDatabase
      .ref("online")
      .child(this.state.currentDriver.id)
      .child("conection")
      .remove();
  };

  handleOnCloseDrawer = () => {
    this.setState({
      isVisibleDrawer: false,
      // currentDriver: null,
      // isForRender: false,
      // zoom: 12,
    });
  };

  handleListenerKeyboard = (e) => {
    if (e.key === "Escape") {
      this.setState({ isShowMoreInfoDriver: false });
    }
  };

  detailsDriver() {
    const style = {
      wrapperProfile: { justifyContent: "center", display: "flex" },
      wrapperMoreInformationUser: { padding: "16px 0", background: "#2a86ff" },
      paddingAroundLevelOne: { padding: "2em 1em" },
      colorWhite: { color: "white" },
      titleStyle: { color: "white", margin: 0 },
    };

    const currentDriver = this.state.currentDriver;

    return currentDriver ? (
      <Drawer
        title="Detalles de usuario"
        placement="right"
        width={320}
        className="wrapper-drawer--driver"
        closable={false}
        onClose={this.handleOnCloseDrawer}
        visible={this.state.isVisibleDrawer}
      >
        <Row>
          <Col xs={12} style={style.wrapperProfile}>
            <Avatar
              className="wrapper-profile"
              src={
                currentDriver.profilePicture !== undefined
                  ? currentDriver.profilePicture
                  : currentDriver.profile_url
              }
              shape="square"
              size={200}
            />
          </Col>
        </Row>
        <Row center="xs" style={style.wrapperMoreInformationUser}>
          <Col xs={12}>
            <Title level={4} style={style.titleStyle}>
              {`${
                currentDriver.firstName !== undefined
                  ? currentDriver.firstName
                  : currentDriver.first_name
              } ${
                currentDriver.lastName !== undefined
                  ? currentDriver.lastName
                  : currentDriver.last_name
              }`}
            </Title>
          </Col>
          <Col xs={12}>
            <Text style={style.colorWhite}>
              <Badge status={currentDriver.isOffline ? "error" : "success"} />
              {currentDriver.isOffline ? "Desconectado" : "Conectado"}
            </Text>
          </Col>
          <Col xs={12}>
            <Text style={style.colorWhite}>{currentDriver.phoneNumber}</Text>
          </Col>
        </Row>
      </Drawer>
    ) : null;
  }

  render() {
    const { visible } = this.state;
    // console.log(this.state.conductors);

    const { conductors } = this.state;

    let conductorsFiltered = conductors.filter(
      (item) => item.last_location || item.location
    );

    return (
      <div className="container-map">
        <div className="map">
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places`}
            loadingElement={<div style={{ height: `98.6%`, width: "100%" }} />}
            containerElement={
              <div style={{ height: `98.6%`, width: "100%" }} />
            }
            zoom={this.state.zoom}
            mapElement={<div style={{ height: `98.6%`, width: "100%" }} />}
            doneShowingMap={() => {
              this.setState({
                isFirstLoad: false,
              });
            }}
            isForRender={this.state.isForRender}
            center={this.state.center}
            conductors={conductorsFiltered}
            isFirstLoad={this.state.isFirstLoad}
            state={this.state}
            onMapReady={(ref) => {
              if (ref && this.state.map == null) {
                this.setState({ map: ref });
              }
            }}
            onMapClick={(conductor) => {
              this.setState({
                isShowMoreInfoDriver: true,
                currentDriver: conductor,
              });
            }}
            onHiddenWindowInfoDriver={() => {
              this.setState({ isShowMoreInfoDriver: false });
            }}
            isShowMoreInfoDriver={this.state.isShowMoreInfoDriver}
          />
        </div>

        <Row>
          <Col xs={12}>
            <Row className="wrapper-container--users">
              <Modal
                title="Analisis del movil"
                width={1000}
                visible={this.state.showTestModal}
                onOk={() => {
                  this.removeData();
                  this.setState({
                    showTestModal: false,
                  });
                }}
                onCancel={() => {
                  this.removeData();
                  this.setState({
                    showTestModal: false,
                  });
                }}
              >
                <Row>
                  <Col md={6}>
                    <img width={400} src={phoneImagePlaceHolder} alt="" />
                  </Col>
                  <Col md={6} style={{ display: "flex", alignItems: "center" }}>
                    <Steps
                      direction="vertical"
                      current={this.state.currentStep}
                    >
                      <Step
                        title="Obteniendo ubicación"
                        description={`(${
                          this.state.new_location
                            ? `${this.state.new_location.latitude}, ${this.state.new_location.longitude}`
                            : ""
                        })`}
                        icon={
                          this.state.currentStep == 0 ? (
                            <Icon type="loading" />
                          ) : null
                        }
                      />
                      <Step
                        title="Obteniendo datos del dispositivo"
                        description={this.state.mobile}
                        icon={
                          this.state.currentStep == 1 ? (
                            <Icon type="loading" />
                          ) : null
                        }
                      />
                      <Step
                        title="Obteniendo estado del servicio"
                        description={this.state.service}
                        icon={
                          this.state.currentStep == 2 ? (
                            <Icon type="loading" />
                          ) : null
                        }
                      />

                      <Step
                        title="Obteniendo datos del conexion"
                        description={this.state.conection}
                        icon={
                          this.state.currentStep == 3 ? (
                            <Icon type="loading" />
                          ) : null
                        }
                      />
                    </Steps>
                  </Col>
                </Row>
              </Modal>

              <Col xs={12}>
                <Row
                  middle="xs"
                  style={{ justifyContent: "center", height: "100%" }}
                >
                  <Tabs
                    centered
                    className="wrapper-tabs-filter-map"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <TabPane
                      tab="Conectados"
                      key="1"
                      style={{ height: "100%" }}
                    >
                      <Row style={{ height: "100%", overflow: "scroll" }}>
                        <Col xs={12} style={{ height: 100 }}>
                          <List
                            style={{ padding: "0 0 5em" }}
                            itemLayout="horizontal"
                            dataSource={this.state.conductors}
                            renderItem={(item) => (
                              <List.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (item.last_location) {
                                    this.setState({
                                      center: {
                                        lat: item.last_location.lat,
                                        lng: item.last_location.lon,
                                      },
                                      zoom: 18,
                                      isForRender: true,
                                    });

                                    //this.handleOnSelectDriver(item);
                                  }
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : item.profile_url
                                      }
                                    />
                                  }
                                  title={
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >{`${
                                      item.firstName !== undefined
                                        ? item.firstName
                                        : item.first_name
                                    } ${
                                      item.lastName !== undefined
                                        ? item.lastName
                                        : item.last_name
                                    }`}</p>
                                  }
                                  description={
                                    <Row>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          {item.phoneNumber}
                                        </p>
                                      </Col>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          Ultima fecha de envio de ubicacion :{" "}
                                          {item.last_date_location}
                                        </p>

                                        <Tag color="#2db7f5">
                                          {item.isAvailable ? "Disponible en la app" : "Ocupado"}
                                        </Tag>
                                      </Col>
                                    </Row>
                                  }
                                />
                                <MoreOptionsDriver
                                  onRemoveService={() => {
                                    firebaseDatabase
                                      .ref("online")
                                      .child(item.id)
                                      .remove()
                                      .then(() => {
                                        message.success(
                                          "Conductor desconectado"
                                        );
                                      });
                                  }}
                                  onSendAlert={() => {
                                    firebaseDatabase
                                      .ref("users")
                                      .child(item.id)
                                      .child("current_rides")
                                      .set({ test: true })
                                      .then(() => {
                                        const { deviceListener } = this;

                                        if (deviceListener != null) {
                                          deviceListener.off();
                                        }

                                        var deviceTestListener = firebaseDatabase
                                          .ref("online")
                                          .child(item.id);

                                        deviceTestListener.on(
                                          "value",
                                          (snapshot) => {
                                            let data = snapshot.val();

                                            if (data.new_location) {
                                              this.setState({
                                                currentStep: 1,
                                                new_location: data.new_location,
                                              });

                                              console.log(
                                                this.state.currentDriver
                                              );
                                            }

                                            if (data.mobile) {
                                              this.setState({
                                                currentStep: 2,
                                                mobile: data.mobile.mobile,
                                              });
                                            }

                                            if (data.service) {
                                              this.setState({
                                                currentStep: 3,
                                                service: data.service.service,
                                              });
                                            }

                                            if (data.conection) {
                                              this.setState({
                                                currentStep: 4,
                                                conection:
                                                  data.conection.conection,
                                              });
                                            }
                                          }
                                        );

                                        this.setState({
                                          showTestModal: true,
                                          currentStep: 0,
                                          currentDriver: item,
                                          deviceListener: deviceTestListener,
                                        });
                                      });
                                  }}
                                  onShowDetails={(e) =>
                                    this.handleOnSelectDriver(item)
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tab="Conductor rumbo a recoger cliente" key="2">
                      <Row style={{ height: "100%", overflow: "scroll" }}>
                        <Col xs={12} style={{ height: 100 }}>
                          <List
                            style={{ padding: "0 0 5em" }}
                            itemLayout="horizontal"
                            dataSource={this.state.conductors.filter(
                              (conductor) => {
                                if (conductor.transaction) {
                                  let isFiltered = false;
                                  if (
                                    conductor.transaction.status == "accepted"
                                  ) {
                                    isFiltered = true;
                                  }
                                  return isFiltered;
                                }

                                return false;
                              }
                            )}
                            renderItem={(item) => (
                              <List.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (item.last_location) {
                                    this.setState({
                                      center: {
                                        lat: item.last_location.lat,
                                        lng: item.last_location.lon,
                                      },
                                      zoom: 18,
                                      isForRender: true,
                                    });
                                  }
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : item.profile_url
                                      }
                                    />
                                  }
                                  title={
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >{`${
                                      item.firstName !== undefined
                                        ? item.firstName
                                        : item.first_name
                                    } ${
                                      item.lastName !== undefined
                                        ? item.lastName
                                        : item.last_name
                                    }`}</p>
                                  }
                                  description={
                                    <Row>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          {item.phoneNumber}
                                        </p>
                                      </Col>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          Ultima fecha de envio de ubicacion :
                                        </p>
                                      </Col>
                                    </Row>
                                  }
                                />
                                <MoreOptionsDriver
                                  onRemoveService={() => {}}
                                  onSendAlert={() => {}}
                                  onShowDetails={(e) =>
                                    this.handleOnSelectDriver(item)
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tab="Conductor esperando cliente" key="3">
                      <Row style={{ height: "100%", overflow: "scroll" }}>
                        <Col xs={12} style={{ height: 100 }}>
                          <List
                            style={{ padding: "0 0 5em" }}
                            itemLayout="horizontal"
                            dataSource={this.state.conductors.filter(
                              (conductor) => {
                                if (conductor.transaction) {
                                  return (
                                    conductor.transaction.status ==
                                    "conductor_arrived"
                                  );
                                }

                                return false;
                              }
                            )}
                            renderItem={(item) => (
                              <List.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (item.last_location) {
                                    this.setState({
                                      center: {
                                        lat: item.last_location.lat,
                                        lng: item.last_location.lon,
                                      },
                                      zoom: 18,
                                      isForRender: true,
                                    });

                                    //this.handleOnSelectDriver(item);
                                  }
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : item.profile_url
                                      }
                                    />
                                  }
                                  title={
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >{`${
                                      item.firstName !== undefined
                                        ? item.firstName
                                        : item.first_name
                                    } ${
                                      item.lastName !== undefined
                                        ? item.lastName
                                        : item.last_name
                                    }`}</p>
                                  }
                                  description={
                                    <Row>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          {item.phoneNumber}
                                        </p>
                                      </Col>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          Ultima fecha de envio de ubicacion :
                                        </p>
                                      </Col>
                                    </Row>
                                  }
                                />
                                <MoreOptionsDriver
                                  onRemoveService={() => {}}
                                  onSendAlert={() => {}}
                                  onShowDetails={(e) =>
                                    this.handleOnSelectDriver(item)
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tab="Conductor en destino" key="4">
                      <Row
                        style={{
                          padding: "2em 1em 0",
                          height: "90%",
                          overflow: "scroll",
                        }}
                      >
                        <Col xs={12}>
                          <List
                            itemLayout="horizontal"
                            dataSource={this.state.conductors.filter(
                              (conductor) => {
                                if (conductor.transaction) {
                                  return (
                                    conductor.transaction.status ==
                                    "conductor_driving_destination"
                                  );
                                }

                                return false;
                              }
                            )}
                            renderItem={(item) => (
                              <List.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (item.last_location) {
                                    this.setState({
                                      center: {
                                        lat: item.last_location.lat,
                                        lng: item.last_location.lon,
                                      },
                                      zoom: 18,
                                      isForRender: true,
                                    });

                                    //this.handleOnSelectDriver(item);
                                  }
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : item.profile_url
                                      }
                                    />
                                  }
                                  title={
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >{`${
                                      item.firstName !== undefined
                                        ? item.firstName
                                        : item.first_name
                                    } ${
                                      item.lastName !== undefined
                                        ? item.lastName
                                        : item.last_name
                                    }`}</p>
                                  }
                                  description={
                                    <Row>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          {item.phoneNumber}
                                        </p>
                                      </Col>
                                      <Col xs={12}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          Ultima fecha de envio de ubicacion :
                                        </p>
                                      </Col>
                                    </Row>
                                  }
                                />
                                <MoreOptionsDriver
                                  onRemoveService={() => {}}
                                  onSendAlert={() => {}}
                                  onShowDetails={(e) =>
                                    this.handleOnSelectDriver(item)
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Row>
                {this.detailsDriver()}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MapView;

const MoreOptionsDriver = (props) => {
  return (
    <div>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <a href={null} onClick={props.onRemoveService}>
                Desconectar
              </a>
            </Menu.Item>
            <Menu.Item key="1">
              <a href={null} onClick={props.onSendAlert}>
                Hacer analisis del dispositivo
              </a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href={null} onClick={props.onShowDetails}>
                Ver detalles
              </a>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Icon type="more" style={{ color: "#5f5f5f", fontSize: 16 }} />
      </Dropdown>
    </div>
  );
};
