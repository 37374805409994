import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Menu, Dropdown } from "antd";
import {
  Tabs,
  Radio,
  Card,
  Icon,
  Modal,
  Button,
  Input,
  Select,
  Alert,
  message,
  Switch,
  DatePicker,
} from "antd";
import axios from "axios";
import { getForkTsCheckerWebpackPluginHooks } from "fork-ts-checker-webpack-plugin/lib/hooks";
import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import TemplateLogo from "../../img/logo-brif.png";
import { getDirectionsFromMaps } from "../../helpers/ServerHelper";

import { BASE_URL, API_GOOGLE_KEY } from "./../../data/constants";

const { TabPane } = Tabs;
const { Meta } = Card;
const { confirm } = Modal;
const { Option } = Select;

const Map = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 19.382596, lng: -99.153247 }}
    center={props.markerPosition}
    onClick={props.onMapClick}
  >
    {props.isMarkerShown && <Marker position={props.markerPosition} />}
  </GoogleMap>
));

function getPerc(num, percent) {
  return Number(num) - (Number(percent) / 100) * Number(num);
}

export class Stores extends Component {
  constructor() {
    super();
    this.state = {
      stores: [],
      client: {},
      visible: false,
      hasAlreadyPickedLocation: false,
      title: ``,
      states: [],
      storesToShow: [],
      sub_categories: [],
      loadingUpload: false,
      storeToRegister: { type: "food", isSearchMarker: true },
      errorMessage: "",
      activeKey: "1",
    };

    this.add = this.add.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    const { name, limit_credit, email, expiration_credit } =
      this.state.storeToRegister;
    const { hasAlreadyPickedLocation } = this.state;

    if (name) {
      if (email) {
        this.setState({
          loadingUpload: true,
        });
        axios
          .post(`${BASE_URL}/super_user/store`, {
            ...this.state.storeToRegister,
          })
          .then((response) => {
            console.log(response);

            if (response.status == 200) {
              let data = response.data;

              let storeToAdd = {
                ...data.result,
                ...this.state.storeToRegister,
              };

              this.setState({
                storeToRegister: {},
                visible: false,
              });

              const { stores } = this.state;

              stores.push(storeToAdd);

              this.setState({
                stores,
                storesToShow: stores,
              });

              this.setState({
                loadingUpload: false,
              });

              this.setState({ activeKey: "1" });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorMessage: `Ha ocurrido un error con el servidor, intente mas tarde`,
              loadingUpload: false,
            });
          });
      } else {
        this.setState({
          errorMessage: `Se necesita un correo electronico`,
        });
      }
    } else {
      this.setState({
        errorMessage: `Se necesita un nombre`,
      });
    }
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    axios.get(`${BASE_URL}/super_user/stores`).then((response) => {
      if (response.status == 200) {
        console.log(response.data.result.length);
        let stores = response.data.result.map((item) => {
          return {
            ...item,
            isAvailable: item.isAvailable ? item.isAvailable : false,
          };
        });

        console.log(stores);

        this.setState({
          stores,
          storesToShow: stores,
        });
      }
    });
  }

  add(array, value) {
    if (array.indexOf(value) === -1) {
      array.push(value);
      this.setState({
        categories: array,
      });
    }
  }

  updateData(idStore, data) {
    data["id"] = idStore;

    delete data._id;

    axios
      .put(`${BASE_URL}/super_user/store/${idStore}`, {
        ...data,
      })
      .then((response) => {
        message.success("Datos actualizados");
      });
  }

  render() {
    return (
      <Row style={{ marginLeft: "2em", marginTop: "2em" }}>
        <Modal
          title="Crear Comercio"
          visible={this.state.visible}
          onOk={this.handleOk}
          width={1000}
          confirmLoading={this.state.loadingUpload}
          okText="Crear Comercio"
          onCancel={this.handleCancel}
        >
          <Row style={{ justifyContent: "left" }}>
            <Col md={12}>
              <div>
                <h1 style={{ marginBottom: 0, marginTop: 0 }}>
                  Llene correctamente los datos
                </h1>
              </div>

              {this.state.errorMessage ? (
                <Alert
                  description={this.state.errorMessage}
                  type="error"
                  closable
                />
              ) : (
                ""
              )}
            </Col>

            <Col md={12} style={{ marginTop: "1em" }}>
              <Input
                placeholder="Nombre del comercio"
                value={this.state.storeToRegister.name}
                onChange={(e) => {
                  this.setState({
                    storeToRegister: {
                      ...this.state.storeToRegister,
                      name: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: "1em" }}>
              <Input
                placeholder="Email"
                value={this.state.storeToRegister.email}
                onChange={(e) => {
                  this.setState({
                    storeToRegister: {
                      ...this.state.storeToRegister,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </Col>

            <Col md={12} style={{ marginTop: "1em" }}>
              <Input
                placeholder="Pagina web"
                value={this.state.storeToRegister.website}
                onChange={(e) => {
                  this.setState({
                    storeToRegister: {
                      ...this.state.storeToRegister,
                      website: e.target.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </Modal>

        <div style={{ width: "100%" }} className="title">
          <div>
            <div>
              <h1 style={{ marginBottom: 0, marginTop: 0 }}>Comercios</h1>
            </div>

            <div
              style={{ width: "100%", textAlign: "right", paddingRight: "4em" }}
            >
              <Button type="primary" onClick={this.showModal}>
                Agregar Comercio
              </Button>
            </div>
          </div>

          <Row style={{ marginTop: "1.5em", marginLeft: "0.3em" }}>
            {this.state.storesToShow.map((store, y) => {
              let url_thumnail = store.url_logo;

              if (!url_thumnail) {
                url_thumnail = TemplateLogo;
              }

              return (
                <Col
                  key={y}
                  md={3}
                  style={{ margin: "0.5em", padding: 0, cursor: "pointer" }}
                  className="card"
                >
                  <Row end="xs" style={{ position: "relative" }}>
                    <Col
                      xs={12}
                      style={{ position: "absolute", padding: "1.25em" }}
                    >
                      {/* <Switch
                        checked={store.isAvailable}
                        style={{ zIndex: 999 }}
                        onChange={(isSelected) => {
                          const { storesToShow } = this.state;
                          storesToShow[y].isAvailable = isSelected;

                          this.updateData(store._id, {
                            isAvailable: isSelected,
                          });

                          this.setState({
                            storesToShow,
                          });
                        }}
                      /> */}
                    </Col>
                  </Row>
                  <Card
                    className="store-container"
                    style={{ width: "100%" }}
                    actions={[
                      // <Icon type="edit" key="edit" />,
                      <Icon
                        type="delete"
                        key="ellipsis"
                        onClick={() => {
                          confirm({
                            title: "¿Estas seguro de eliminar esta tienda?",
                            content: "Esta accion no podra rehacerse",
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            onOk: () => {
                              let originalArray = this.state.stores.filter(
                                (item, position) => {
                                  return item._id != store._id;
                                }
                              );

                              let ridesToShow = this.state.storesToShow.filter(
                                (item, position) => {
                                  return item._id != store._id;
                                }
                              );

                              this.setState({
                                stores: originalArray,
                                storesToShow: ridesToShow,
                              });

                              axios
                                .delete(
                                  `${BASE_URL}/super_user/store/${store._id}`
                                )
                                .then((response) => {
                                  if (response.status == 200) {
                                    message.success("Comercio eliminado");
                                  }
                                });
                            },
                            onCancel() {
                              console.log("Cancel");
                            },
                          });
                        }}
                      />,
                    ]}
                  >
                    <div
                      className="store-banner"
                      style={{
                        background: `#1976d2
                      `,
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `/dashboard/store/${store._id}`
                        );
                      }}
                    >
                      <div className="thumbnail">
                        <div className="logo">
                          <img
                            src={url_thumnail}
                            alt=""
                            style={{ width: "100%", height : "100%" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: "1em ",
                        marginTop: "4.2em",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                        <p style={{ marginTop: "2em" }}>{store.name}</p>
                      </strong>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </Row>
    );
  }
}

export default Stores;
